/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable camelcase */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { handleMessages } from '../_helpers/utilities'
import {
  IDeleteIds,
  IInviteUserParams,
  IInviteUserState,
  IUserLIstFilters,
} from '../_models/inviteUserInterface'
import businessUserService from '../_services/inviteUserService'
import { RootState } from '../_store/store'
import { startLoader, stopLoader } from './loaderSlice'
import { errorToast, infoToast, successToast } from './toastSlice'

const initialState: IInviteUserState = {
  inviteUsersList: {
    current_page: 1,
    data: [],
    total: 20,
    per_page: 10,
    last_page: 0,
  },
  facilityList: [],
  openDrawer: false,
  isLoadingUsers: false,
  isReinviteModalOpen: false,
  isDeleteModalOpen: false,
  isLastUserPage: false,
  UserListFilter: {
    name: '',
    sort_by: 'name',
    sort_order: 'asc',
    page: 1,
    per_page: 10,
    role_list: [],
  },
  invitedUserDetails: {},
  drawerType: 'create',
  isFacilitySelection: false,
}
export const getUsersList = createAsyncThunk(
  '/getUsersList',
  async (filters: IUserLIstFilters, { dispatch, rejectWithValue }) => {
    try {
      const response = await businessUserService.getUsersList(filters)

      return response
    } catch (err: any) {
      dispatch(errorToast(handleMessages(err.message)))
      return rejectWithValue(err)
    }
  },
)

export const getFacilityList = createAsyncThunk(
  '/getFacilityList',
  async (_, { dispatch, rejectWithValue }) => {
    try {
      const response = await businessUserService.getFacilityList()
      return response
    } catch (err: any) {
      dispatch(errorToast(handleMessages(err.message)))
      return rejectWithValue(err)
    }
  },
)

export const inviteUser = createAsyncThunk(
  '/inviteUser',
  async (params: IInviteUserParams, { dispatch, rejectWithValue, getState }) => {
    dispatch(startLoader())
    try {
      const response = await businessUserService.inviteUser(params)
      dispatch(stopLoader())
      dispatch(successToast(handleMessages(response.message)))
      const { inviteUser } = getState() as { inviteUser: IInviteUserState }
      dispatch(getUsersList(inviteUser.UserListFilter))

      return response
    } catch (err: any) {
      dispatch(stopLoader())
      dispatch(errorToast(handleMessages(err.message)))
      return rejectWithValue(err)
    }
  },
)
export const reinviteUser = createAsyncThunk(
  '/reinviteUser',
  async (id: number, { dispatch, rejectWithValue, getState }) => {
    dispatch(startLoader())

    try {
      const response = await businessUserService.reinviteUser(id)
      dispatch(stopLoader())
      dispatch(successToast(handleMessages(response.message)))
      const { inviteUser } = getState() as { inviteUser: IInviteUserState }
      dispatch(getUsersList(inviteUser.UserListFilter))
    } catch (err: any) {
      dispatch(stopLoader())
      dispatch(errorToast(handleMessages(err.message)))
      return rejectWithValue(err)
    }
  },
)
export const deleteUser = createAsyncThunk(
  '/deleteUser',
  async (ids: IDeleteIds, { dispatch, rejectWithValue, getState }) => {
    dispatch(startLoader())

    try {
      const response = await businessUserService.deleteUser(ids)
      dispatch(stopLoader())
      dispatch(successToast(handleMessages(response.message)))
      const { inviteUser } = getState() as { inviteUser: IInviteUserState }
      if (inviteUser.isLastUserPage) {
        if (inviteUser.inviteUsersList.data.length === 1) {
          dispatch(
            getUsersList({
              ...inviteUser.UserListFilter,
              page: inviteUser.UserListFilter.page - 1,
            }),
          )
        } else {
          dispatch(getUsersList(inviteUser.UserListFilter))
        }
      } else {
        dispatch(getUsersList(inviteUser.UserListFilter))
      }
    } catch (err: any) {
      dispatch(stopLoader())
      dispatch(errorToast(handleMessages(err.message)))
      return rejectWithValue(err)
    }
  },
)
export const getUserDetails = createAsyncThunk(
  '/getUserDetails',
  async (id: number, { dispatch, rejectWithValue }) => {
    dispatch(startLoader())
    try {
      const response = await businessUserService.getUserDetails(id)
      dispatch(stopLoader())
      return response
    } catch (err: any) {
      dispatch(errorToast(handleMessages(err.message)))
      dispatch(stopLoader())
      return rejectWithValue(err)
    }
  },
)
export const editInvitedUser = createAsyncThunk(
  '/editInvitedUser',
  async (params: IInviteUserParams, { dispatch, rejectWithValue, getState }) => {
    dispatch(startLoader())
    try {
      const { inviteUser } = getState() as { inviteUser: IInviteUserState }
      const id = inviteUser?.invitedUserDetails?.id
      const response = await businessUserService.editInvitedUser(id, params)
      dispatch(stopLoader())
      dispatch(successToast(handleMessages(response.message)))
      dispatch(getUsersList(inviteUser.UserListFilter))
      return response
    } catch (err: any) {
      dispatch(stopLoader())
      dispatch(errorToast(handleMessages(err.message)))
      return rejectWithValue(err)
    }
  },
)
export const checkIfUserIsInvited = createAsyncThunk(
  '/checkIfUserIsInvited',
  async (email: string, { dispatch, rejectWithValue }) => {
    dispatch(startLoader())
    try {
      const params = {
        email: email,
      }
      const response = await businessUserService.checkIfUserIsInvited(params)
      dispatch(stopLoader())
      return response
    } catch (err: any) {
      dispatch(stopLoader())
      if (err?.isInfo) {
        dispatch(infoToast(handleMessages(err?.message)))
      } else {
        dispatch(errorToast(handleMessages(err?.message)))
      }

      return rejectWithValue(err)
    }
  },
)

export const allowFacilitySelection = createAsyncThunk(
  '/allowFacilitySelection',
  async (id: number, { dispatch, rejectWithValue }) => {
    dispatch(startLoader())
    try {
      const response = await businessUserService.allowFacilitySelection(id)
      dispatch(stopLoader())
      return response
    } catch (err: any) {
      dispatch(errorToast(handleMessages(err.message)))
      dispatch(stopLoader())
      return rejectWithValue(err)
    }
  },
)
export const inviteUserSlice = createSlice({
  name: 'inviteUser',
  initialState,
  reducers: {
    openInviteDrawer: (state) => {
      state.openDrawer = true
      state.drawerType = 'create'
    },
    closeInviteDrawer: (state) => {
      state.openDrawer = false
      state.drawerType = 'create'
      state.invitedUserDetails = initialState.invitedUserDetails
    },
    changeUserFilters: (state, action) => {
      state.UserListFilter = action.payload
    },
    openReinviteModal: (state) => {
      state.isReinviteModalOpen = true
    },
    closeReinviteModal: (state) => {
      state.isReinviteModalOpen = false
    },
    openUserDeleteModal: (state) => {
      state.isDeleteModalOpen = true
    },
    closeUserDeleteModal: (state) => {
      state.isDeleteModalOpen = false
    },
    resetUserDetails: (state) => {
      state.invitedUserDetails = initialState.invitedUserDetails
    },
    resetFacilitySelection: (state) => {
      state.isFacilitySelection = initialState.isFacilitySelection
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getUsersList.fulfilled, (state, action) => {
      state.inviteUsersList = action.payload.data
      state.isLoadingUsers = false
      if (
        state.inviteUsersList.current_page === state.inviteUsersList.last_page &&
        state.inviteUsersList.last_page !== 1
      ) {
        state.isLastUserPage = true
      } else {
        state.isLastUserPage = false
      }
    })
    builder.addCase(getUsersList.pending, (state) => {
      state.isLoadingUsers = true
    })
    builder.addCase(getUsersList.rejected, (state) => {
      state.isLoadingUsers = false
    })
    builder.addCase(getFacilityList.fulfilled, (state, action) => {
      state.facilityList = action.payload.data.facilities.data
    })
    builder.addCase(inviteUser.fulfilled, (state) => {
      state.openDrawer = false
    })
    builder.addCase(getUserDetails.fulfilled, (state, action) => {
      state.invitedUserDetails = action.payload.data
      state.openDrawer = true
      state.drawerType = 'edit'
    })
    builder.addCase(editInvitedUser.fulfilled, (state) => {
      state.openDrawer = false
      state.invitedUserDetails = initialState.invitedUserDetails
    })
    builder.addCase(checkIfUserIsInvited.fulfilled, (state, action) => {
      state.invitedUserDetails = action.payload.data
    })
    builder.addCase(checkIfUserIsInvited.rejected, (state) => {
      state.invitedUserDetails = initialState.invitedUserDetails
    })
    builder.addCase(checkIfUserIsInvited.pending, (state) => {
      state.invitedUserDetails = initialState.invitedUserDetails
    })
    builder.addCase(allowFacilitySelection.fulfilled, (state, action) => {
      state.isFacilitySelection = action.payload.data.facility_selection
    })
    builder.addCase(allowFacilitySelection.rejected, (state) => {
      state.isFacilitySelection = initialState.isFacilitySelection
    })
  },
})

export const {
  closeInviteDrawer,
  openInviteDrawer,
  changeUserFilters,
  closeReinviteModal,
  openReinviteModal,
  openUserDeleteModal,
  closeUserDeleteModal,
  resetUserDetails,
  resetFacilitySelection,
} = inviteUserSlice.actions
export const inviteUserState = (state: RootState) => state.inviteUser
const inviteUserReducer = inviteUserSlice.reducer
export default inviteUserReducer
