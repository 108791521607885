import { ISlotsFormData } from './../_models/commonInterface'
/* eslint-disable camelcase */
/* eslint-disable @typescript-eslint/no-explicit-any */

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { getUserTypeFromToken, isAuthenticated } from '../_helpers/cookie'
import { History } from '../_helpers/history'
import { getDomainNames, isBusinessURL } from '../_helpers/routes'
import {
  getBookingsUpdatedAvailableSeatsCount,
  getBookingsUpdatedSeats,
} from '../_helpers/seatBooking'
import { handleMessages } from '../_helpers/utilities'
import { IDashboardState } from '../_models/dashboardInterface'
import { IFacilityState } from '../_models/facilityInterface'
import { IUserState } from '../_models/userInterface'
import newBookingService from '../_services/newBookingService'
import { RootState } from '../_store/store'
import {
  IBlockSeats,
  IBookingDetails,
  ICheckForFloors,
  IConfirmRescheduleBookingFromMail,
  IFloorsNewBooking,
  IGetFloors,
  IGuestUserDetails,
  ILockedSeats,
  IMailLinkDetails,
  INewBookingDetails,
  IPreviousBookingInfo,
  IRescheduleBookingFromMail,
  ISeatInFloors,
  ISeats,
  ITokenIds,
  IUserEmails,
} from './../_models/newBookingInterface'
import { getBookingList, getDashboardStatistics } from './dashboardSlice'
import { changeBusinessDetailsFilter, resetFacilityListData } from './facilitySlice'
import { startLoader, stopLoader } from './loaderSlice'
import { errorToast, infoToast, successToast } from './toastSlice'

const initialState: INewBookingDetails = {
  newBookingLocationInformation: [],
  phoneNumberInformation: {},
  slotInformation: {},
  bookingDetails: {},
  drawerStatus: false,
  drawerMode: false,
  cancelModal: false,
  drawerType: 'create',
  tokenIds: [],
  bookingSuccessInformation: {},
  bookingSuccessModalStatus: false,
  bookingConfirmationModalStatus: false,
  slotInformationMessage: '',
  isLoadingbookingDetails: false,
  isRegisteredMail: false,
  isLoadingSlots: false,
  isLoadingLocations: false,
  isLoadingFacilities: false,
  noActiveToken: false,
  phoneResponseLoading: false,

  // floor plan
  seatAvailable: null,
  seatBookingNotAvailableReason: '',
  isExactTiming: 0,
  floors: [],
  isLoadingFloors: false,
  floorDetails: {
    seats: [],
    availableSeats: 0,
    colorCodes: {
      available_seat_color: 'transparent',
      booked_seat_color: '#f6f6f6',
      blocked_seat_color: '#f6f6f6',
      unavailable_seat_color: '#f6f6f6',
      selected_seat_color: '#1bb562',
      available_seat_border_color: '#1bb562',
      booked_seat_border_color: '#f6f6f6',
      blocked_seat_border_color: '#f6f6f6',
      unavailable_seat_border_color: '#f6f6f6',
      selected_seat_border_color: '#1bb562',
      available_seat_text_color: '#646464',
      booked_seat_text_color: '#595959',
      blocked_seat_text_color: '#595959',
      unavailable_seat_text_color: '#595959',
      selected_seat_text_color: '#ffffff',
    },

    // svg floor details
    image: '',
    seatToFocus: '',
    bookedIds: {},
  },
  isLoadingSeats: false,
  selectedSeats: [],
  previouslyBlockedSeats: [],
  notBlockedSeats: [],
  floorNotAvailable: false,
  bookingSuccessHeaderMessage: '',
  customerFacilityData: {},
  isBookForOthers: false,
  previousBookingInfo: {},
  showPreviousSuggestion: false,
  isValidCancelBookingMailLink: false,
  checkCancelBookingDetails: {},
  cancelBookingDetails: {},
  isValidRescheduleBookingMailLink: false,
  rescheduleBookingDetails: {},
  unregisteredEmails: [],
  isValidConfirmRescheduleLink: false,
  confirmRescheduleDetails: {},
  isConfirmRescheduleModalOpen: false,
  isDuplicatePhoneNumber: false,
  isDifferentPhoneNumber: false,
  bookingDetailsGuest: {},
  isFloorMapLoaded: false,
  phoneValidationMsg: '',
}

export const getLocationsNewBooking = createAsyncThunk(
  '/getLocations',
  async (_, { dispatch, rejectWithValue }) => {
    dispatch(setLocationLoadingStatusTrue())
    try {
      const params = {
        is_booking: 1,
      }
      const response = await newBookingService.getLocationsNewBooking(params)
      dispatch(setLocationLoadingStatusFalse())
      return response
    } catch (err: any) {
      dispatch(setLocationLoadingStatusFalse())
      dispatch(errorToast(handleMessages(err.message)))
      return rejectWithValue(err)
    }
  },
)

export const getPhoneNumber = createAsyncThunk(
  '/getPhoneNumber',
  async (email: string, { dispatch, rejectWithValue }) => {
    dispatch(startLoader())
    try {
      const params = {
        email: email,
      }
      const response = await newBookingService.getPhoneNumber(params)
      dispatch(stopLoader())
      return response
    } catch (err: any) {
      dispatch(stopLoader())
      if (err?.isInfo) {
        dispatch(infoToast(handleMessages(err?.message)))
      } else {
        dispatch(errorToast(handleMessages(err?.message)))
      }

      return rejectWithValue(err)
    }
  },
)

export const getLocationFacilities = createAsyncThunk(
  '/getLocationFacilities',
  async (location: number, { dispatch, rejectWithValue }) => {
    dispatch(setFacilityLoadingStatusTrue())
    try {
      const params = {
        location: location,
        inactive: 0,
      }
      const response = await newBookingService.getLocationFacilities(params)
      dispatch(setFacilityLoadingStatusFalse())
      return response
    } catch (err: any) {
      dispatch(setFacilityLoadingStatusFalse())
      dispatch(errorToast(handleMessages(err.message)))

      return rejectWithValue(err)
    }
  },
)
export const getSlots = createAsyncThunk(
  '/getSlots',
  async (filters: ISlotsFormData, { dispatch, rejectWithValue }) => {
    // dispatch(startLoader())
    try {
      const response = await newBookingService.getSlots(filters)
      // dispatch(stopLoader())
      return response
    } catch (err: any) {
      // dispatch(stopLoader())
      dispatch(errorToast(handleMessages(err.message)))
      dispatch(setSlotErrorMessage(err.message))
      return rejectWithValue(err)
    }
  },
)

export const newBooking = createAsyncThunk(
  '/newBooking',
  async (formData: any, { dispatch, rejectWithValue, getState }) => {
    const { facility } = getState() as { facility: IFacilityState }
    dispatch(startLoader())
    try {
      const response = await newBookingService.newBooking(formData)
      dispatch(stopLoader())
      dispatch(updatePreviouslyBlockedSeats([]))
      dispatch(bookingSuccessModalStatus())
      dispatch(closeDrawer())
      if (getUserTypeFromToken() === 'customer') {
        // To refetch updated facility data list
        dispatch(resetFacilityListData())
        dispatch(
          changeBusinessDetailsFilter({
            ...facility.businessDetailsFilter,
            page: 1,
          }),
        )
      }
      return response
    } catch (err: any) {
      if ('floorNotAvailable' in err.data && err.data.floorNotAvailable) {
        dispatch(updateFloorNotAvailable(err.data.floorNotAvailable))
        dispatch(updateNotBlockedSeats([]))
        dispatch(updatePreviouslyBlockedSeats([]))
      }
      dispatch(stopLoader())
      dispatch(errorToast(handleMessages(err.message)))
      return rejectWithValue(err)
    }
  },
)

export const getBookingDetails = createAsyncThunk(
  '/bookingDetails',
  async (
    getBookingData: { bookingId: number | string; drawerType: string },
    { dispatch, rejectWithValue, getState },
  ) => {
    const { user } = getState() as { user: IUserState }
    if (getBookingData.drawerType === 'edit') {
      dispatch(startLoader())
      if (user?.userData?.type === 'business') dispatch(getLocationsNewBooking())
    } else if (getBookingData.drawerType === 'details') {
      dispatch(openDetailsDrawer())
    }
    try {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { newBooking } = getState() as { newBooking: INewBookingDetails }
      const response = await newBookingService.bookingDetails(getBookingData.bookingId)
      if (getBookingData.drawerType === 'edit') dispatch(openBookingDrawer())
      dispatch(stopLoader())
      return response
    } catch (err: any) {
      dispatch(errorToast(handleMessages(err.message)))
      dispatch(closeDrawer())
      dispatch(stopLoader())
      return rejectWithValue(err)
    }
  },
)

export const updateBooking = createAsyncThunk(
  '/updateBooking',
  async (
    actionParams: {
      formData: any
      isPermissionForKpi?: boolean
    },

    { dispatch, rejectWithValue, getState },
  ) => {
    dispatch(startLoader())
    try {
      const response = await newBookingService.updateBooking(actionParams.formData)
      const { dashboard } = getState() as { dashboard: IDashboardState }
      dispatch(getBookingList(dashboard.dashboardBookingListData.filters))
      dispatch(stopLoader())
      dispatch(closeDrawer())
      dispatch(bookingSuccessModalStatus())

      if (getUserTypeFromToken() === 'business') {
        actionParams.isPermissionForKpi && dispatch(getDashboardStatistics())
      }
      return response
    } catch (err: any) {
      if ('floorNotAvailable' in err.data && err.data.floorNotAvailable) {
        dispatch(updateFloorNotAvailable(err.data.floorNotAvailable))
        dispatch(updateNotBlockedSeats([]))
        dispatch(updatePreviouslyBlockedSeats([]))
      }
      dispatch(stopLoader())
      dispatch(errorToast(handleMessages(err.message)))
      return rejectWithValue(err)
    }
  },
)
export const cancelBooking = createAsyncThunk(
  '/cancelBooking',

  async (
    actionParams: {
      bookingId: ITokenIds
      isPermissionForKpi?: boolean
    },
    { dispatch, rejectWithValue, getState },
  ) => {
    dispatch(startLoader())
    try {
      const response = await newBookingService.cancelBooking(actionParams.bookingId)
      dispatch(successToast(handleMessages(response.message)))
      const { dashboard } = getState() as { dashboard: IDashboardState }
      dispatch(getBookingList(dashboard.dashboardBookingListData.filters))

      actionParams.isPermissionForKpi && dispatch(getDashboardStatistics())
      dispatch(closeCancelBookingModal())
      dispatch(stopLoader())
      dispatch(closeDrawer())
      return response
    } catch (err: any) {
      dispatch(stopLoader())
      dispatch(errorToast(handleMessages(err.message)))
      dispatch(closeCancelBookingModal())
      return rejectWithValue(err)
    }
  },
)

export const getFloors = createAsyncThunk(
  '/getFloors',
  async (params: IGetFloors, { dispatch, rejectWithValue }) => {
    try {
      const response = await newBookingService.getFloors(params)
      return response
    } catch (err: any) {
      dispatch(errorToast(handleMessages(err.message)))

      return rejectWithValue(err)
    }
  },
)

export const checkForFloors = createAsyncThunk(
  '/checkForFloors',
  async (params: ICheckForFloors, { dispatch, rejectWithValue }) => {
    try {
      const response = await newBookingService.checkForFloors(params)
      if (response && response.data.is_seat_booking_available) {
        dispatch(getFloors(params))
      }
      return response
    } catch (err: any) {
      dispatch(errorToast(handleMessages(err.message)))

      return rejectWithValue(err)
    }
  },
)

export const checkFloorsAndUpdateSeatDetails = createAsyncThunk(
  '/checkFloorsAndUpdateSeatDetails',
  async (bookingDetails: IBookingDetails | Record<string, any>, { dispatch, rejectWithValue }) => {
    const getFloorsParams = {
      facility_id: bookingDetails.facility_id as string,
      master_slot_id: bookingDetails.master_slot_id as number,
      date: bookingDetails.date as string,
      token_id: bookingDetails.token_id,
    }
    if (bookingDetails.map_details && bookingDetails.floor) {
      dispatch(changeValue({ isLoadingSeats: true }))
    }
    try {
      const response = await dispatch(checkForFloors(getFloorsParams))

      if (response && response.payload && response.payload.data.is_seat_booking_available) {
        try {
          // for fetching the floor lists
          const response = await dispatch(getFloors(getFloorsParams))

          if (
            response &&
            response.payload &&
            response.payload.data.floors &&
            bookingDetails.map_details &&
            bookingDetails.floor
          ) {
            const floors = response.payload.data.floors

            if (floors.length > 0) {
              const bookedFloor = floors.find(
                (floor: IFloorsNewBooking) => floor.floor_name === bookingDetails.floor,
              )
              if (bookedFloor && bookingDetails.facility_id && bookingDetails.master_slot_id) {
                const getSeatParam: ISeatInFloors = {
                  floor_id: bookedFloor.floor_id,
                  facility_id: bookingDetails.facility_id,
                  master_slot_id: bookingDetails.master_slot_id,
                  date: bookingDetails.date,
                  no_of_seats: bookingDetails.no_of_seats,
                }

                try {
                  // get floor details
                  // eslint-disable-next-line prefer-const
                  let seats: ISeats[] | [] = []
                  if (bookedFloor.floor_type === 1) {
                    const response = await dispatch(getSVGSeatsInFloors(getSeatParam))
                    if (response && response.payload && response.payload.data.seats) {
                      seats = response.payload.data.seats
                    }
                  } else {
                    const response = await dispatch(getCSVSeatsInFloors(getSeatParam))
                    if (response && response.payload && response.payload.data.seats) {
                      seats = response.payload.data.seats
                    }
                  }
                  if (seats) {
                    // get seat names from lockedSeats
                    const bookedSeatsNames = bookingDetails.map_details.lockedSeats.map(
                      (seatObj: ILockedSeats) => seatObj.seat_name,
                    )
                    const bookedSeats = seats.filter((seat: ISeats) =>
                      bookedSeatsNames.includes(seat.seat_name),
                    )
                    const statusUpdatedBookedSeats = bookedSeats.map((seat: ISeats) => {
                      return { ...seat, status: 1 }
                    })
                    dispatch(updateSelectedSeats(statusUpdatedBookedSeats))
                  }
                } catch (err: any) {
                  dispatch(stopLoader())
                  dispatch(errorToast(handleMessages(err.message)))
                  return rejectWithValue(err)
                }
              }
            }
          }
        } catch (err: any) {
          dispatch(stopLoader())
          dispatch(errorToast(handleMessages(err.message)))
          return rejectWithValue(err)
        }
      }

      return response
    } catch (err: any) {
      dispatch(errorToast(handleMessages(err.message)))

      return rejectWithValue(err)
    }
  },
)

export const getCSVSeatsInFloors = createAsyncThunk(
  '/getCSVSeatsInFloors',
  async (params: ISeatInFloors, { dispatch, rejectWithValue }) => {
    try {
      const response = await newBookingService.getCSVSeatsInFloors(params)
      return response
    } catch (err: any) {
      dispatch(errorToast(handleMessages(err.message)))

      return rejectWithValue(err)
    }
  },
)
export const blockSeats = createAsyncThunk(
  '/blockSeats',
  async (params: IBlockSeats, { dispatch, rejectWithValue }) => {
    dispatch(startLoader())
    dispatch(updateFloorNotAvailable(false))
    try {
      const response = await newBookingService.blockSeats(params)
      dispatch(stopLoader())
      if (params.isBookForOthers && params.userEmails) {
        // get unregistered emails from the userEmails before opening the confirmation modal
        dispatch(validateEmails({ user_emails: params.userEmails }))
      } else {
        dispatch(updateBookingConfirmationModalStatus(true))
      }
      return response
    } catch (err: any) {
      if ('floorNotAvailable' in err.data && err.data.floorNotAvailable) {
        dispatch(updateFloorNotAvailable(err.data.floorNotAvailable))
        dispatch(updateNotBlockedSeats([]))
        dispatch(updatePreviouslyBlockedSeats([]))
      } else {
        dispatch(
          updateNotBlockedSeats(
            err.data.not_blocked_seats?.map((seat: any) => ({
              seat_id: seat.seat_id,
            })),
          ),
        )
        dispatch(
          updatePreviouslyBlockedSeats(
            err.data.blocked_seats?.map((seat: any) => ({
              seat_id: seat.seat_id,
            })),
          ),
        )
      }
      dispatch(stopLoader())
      dispatch(errorToast(handleMessages(err.message)))
      return rejectWithValue(err)
    }
  },
)
export const unBlockSeats = createAsyncThunk(
  '/unBlockSeats',
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  async (params: IBlockSeats, { dispatch, rejectWithValue }) => {
    try {
      const response = await newBookingService.blockSeats(params)
      return response
    } catch (err: any) {
      // dispatch(errorToast(handleMessages(err.message)))
      return rejectWithValue(err)
    }
  },
)
export const getSVGSeatsInFloors = createAsyncThunk(
  '/getSVGSeatsInFloors',
  async (params: ISeatInFloors, { dispatch, rejectWithValue }) => {
    try {
      const response = await newBookingService.getSVGSeatsInFloors(params)
      return response
    } catch (err: any) {
      dispatch(errorToast(handleMessages(err.message)))
      return rejectWithValue(err)
    }
  },
)
export const previousBooking = createAsyncThunk(
  '/previousBooking',
  async (facilityId: string, { rejectWithValue, dispatch }) => {
    dispatch(startLoader())
    try {
      const response = await newBookingService.previousBooking(facilityId)
      dispatch(stopLoader())
      return response
    } catch (err: any) {
      dispatch(stopLoader())
      return rejectWithValue(err)
    }
  },
)

export const updatePreviouslyBookedSeat = createAsyncThunk(
  '/updatePreviouslyBookedSeat',
  async (
    bookingDetails: IPreviousBookingInfo | Record<string, any>,
    { dispatch, rejectWithValue },
  ) => {
    try {
      const { facility_id, master_slot_id, schedule_date, floor, no_of_seats, seats } =
        bookingDetails
      const getFloorsParams = { facility_id, master_slot_id, date: schedule_date }
      // If a floor is provided, set isLoadingSeats to true
      if (floor?.floor_id) {
        dispatch(changeValue({ isLoadingSeats: true }))
      }
      const response = await dispatch(checkForFloors(getFloorsParams))
      if (!response?.payload?.data?.is_seat_booking_available) {
        return response // Return response if seat booking is not available
      }
      // Fetch floors data
      const floorsResponse = await dispatch(getFloors(getFloorsParams))
      const floorsData = floorsResponse?.payload?.data?.floors

      // Find booked floor data from list of floors
      if (floorsData?.length > 0 && floor?.floor_id) {
        const bookedFloor = floorsData.find((f: IFloorsNewBooking) => f.floor_id === floor.floor_id)
        if (bookedFloor) {
          const getSeatParam: ISeatInFloors = {
            floor_id: bookedFloor.floor_id,
            facility_id,
            master_slot_id,
            date: schedule_date,
            no_of_seats,
          }
          // Fetch seats data based on floor type
          const response =
            bookedFloor.floor_type === 1
              ? await dispatch(getSVGSeatsInFloors(getSeatParam))
              : await dispatch(getCSVSeatsInFloors(getSeatParam))
          const seatsData = response?.payload?.data?.seats

          if (seatsData) {
            const bookedSeatsNames = seats?.map((seatObj: ILockedSeats) => seatObj.seat_name)
            // Filter booked seats data based on booked seats names
            const bookedSeats = seatsData.filter((seat: ISeats) =>
              bookedSeatsNames?.includes(seat.seat_name),
            )
            // Update status of booked seats
            const statusUpdatedBookedSeats = bookedSeats.map((seat: ISeats) => ({
              ...seat,
              status: 1,
            }))
            dispatch(updateSelectedSeats(statusUpdatedBookedSeats))
          }
        }
      }

      return response
    } catch (err: any) {
      dispatch(stopLoader())
      dispatch(errorToast(handleMessages(err.message)))
      return rejectWithValue(err)
    }
  },
)

export const checkCancelBookingMailLink = createAsyncThunk(
  '/checkCancelBookingMailLink',
  async (emailLinkDetails: IMailLinkDetails, { dispatch, rejectWithValue }) => {
    dispatch(startLoader())
    try {
      const response = await newBookingService.checkCancelBookingMailLink(emailLinkDetails)
      dispatch(stopLoader())
      return response
    } catch (err: any) {
      dispatch(stopLoader())
      History.navigate('/link-expired')
      return rejectWithValue(err)
    }
  },
)

export const cancelBookingFromMailLink = createAsyncThunk(
  '/cancelBookingFromMailLink',
  async (checkCancelBookingDetails: IMailLinkDetails, { dispatch, rejectWithValue }) => {
    dispatch(startLoader())
    try {
      const response = await newBookingService.cancelBookingFromMailLink(checkCancelBookingDetails)
      dispatch(stopLoader())
      History.navigate('mail/booking-canceled')
      return response
    } catch (err: any) {
      dispatch(stopLoader())
      dispatch(errorToast(handleMessages(err.message)))
      return rejectWithValue(err)
    }
  },
)

export const validateEmails = createAsyncThunk(
  '/validateEmails',
  async (params: IUserEmails, { dispatch, rejectWithValue }) => {
    dispatch(startLoader())
    try {
      const response = await newBookingService.validateEmails(params)
      dispatch(stopLoader())
      dispatch(updateBookingConfirmationModalStatus(true))
      return response
    } catch (err: any) {
      dispatch(stopLoader())
      dispatch(errorToast(handleMessages(err.message)))
      return rejectWithValue(err)
    }
  },
)

export const checkRescheduleBookingMailLink = createAsyncThunk(
  '/checkRescheduleBookingMailLink',
  async (emailLinkDetails: IMailLinkDetails, { dispatch, rejectWithValue }) => {
    dispatch(startLoader())
    try {
      const response = await newBookingService.checkRescheduleBookingMailLink(emailLinkDetails)
      dispatch(stopLoader())

      if (response) {
        const domainNames = getDomainNames()
        const loggedInUserType = getUserTypeFromToken()
        const userAuthorized = response?.token_status === 'unauthorized' ? false : true

        if (isBusinessURL() && !isAuthenticated()) {
          History.navigate(`/business/${domainNames[0]}${window.location.search}`)
        }

        if (
          isBusinessURL() &&
          isAuthenticated() &&
          loggedInUserType &&
          loggedInUserType === 'customer' &&
          userAuthorized
        ) {
          if (domainNames.length > 3) {
            window.location.replace(
              `${window.location.protocol}//${domainNames[1]}.slashq.${domainNames[3]}/customer/business/${domainNames[0]}${window.location.search}`,
            )
          } else {
            window.location.replace(
              `${window.location.protocol}//slashq.io/customer/business/${domainNames[0]}${window.location.search}`,
            )
          }
        }

        if (
          isAuthenticated() &&
          isBusinessURL() &&
          loggedInUserType &&
          loggedInUserType === 'business' &&
          userAuthorized
        ) {
          if (domainNames.length > 3) {
            window.location.replace(
              `${window.location.protocol}//${domainNames[1]}.slashq.${domainNames[3]}/admin/business/${domainNames[0]}${window.location.search}`,
            )
          } else {
            window.location.replace(
              `${window.location.protocol}//slashq.io/admin/business/${domainNames[0]}${window.location.search}`,
            )
          }
        }
      }
      return response
    } catch (err: any) {
      dispatch(stopLoader())
      History.navigate('/link-expired')
      return rejectWithValue(err)
    }
  },
)

export const checkConfirmRescheduleBookingMailLink = createAsyncThunk(
  '/checkConfirmRescheduleBookingMailLink',
  async (emailLinkDetails: IMailLinkDetails, { dispatch, rejectWithValue }) => {
    dispatch(startLoader())
    try {
      const response = await newBookingService.checkConfirmRescheduleBookingMailLink(
        emailLinkDetails,
      )
      dispatch(stopLoader())

      if (response && !response?.token_status) {
        const domainNames = getDomainNames()
        const loggedInUserType = getUserTypeFromToken()
        const userAuthorized = response?.token_status === 'unauthorized' ? false : true

        if (isBusinessURL() && !isAuthenticated()) {
          History.navigate(`/business/${domainNames[0]}${window.location.search}`)
        }

        if (
          isBusinessURL() &&
          isAuthenticated() &&
          loggedInUserType &&
          loggedInUserType === 'customer' &&
          userAuthorized
        ) {
          if (domainNames.length > 3) {
            window.location.replace(
              `${window.location.protocol}//${domainNames[1]}.slashq.${domainNames[3]}/customer/business/${domainNames[0]}${window.location.search}`,
            )
          } else {
            window.location.replace(
              `${window.location.protocol}//slashq.io/customer/business/${domainNames[0]}${window.location.search}`,
            )
          }
        }

        if (
          isAuthenticated() &&
          isBusinessURL() &&
          loggedInUserType &&
          loggedInUserType === 'business' &&
          userAuthorized
        ) {
          if (domainNames.length > 3) {
            window.location.replace(
              `${window.location.protocol}//${domainNames[1]}.slashq.${domainNames[3]}/admin/business/${domainNames[0]}${window.location.search}`,
            )
          } else {
            window.location.replace(
              `${window.location.protocol}//slashq.io/admin/business/${domainNames[0]}${window.location.search}`,
            )
          }
        }
      }
      return response
    } catch (err: any) {
      dispatch(stopLoader())
      History.navigate('/link-expired')
      return rejectWithValue(err)
    }
  },
)

export const rescheduleBookingFromMail = createAsyncThunk(
  '/rescheduleBookingFromMail',
  async (linkData: IRescheduleBookingFromMail, { dispatch, rejectWithValue }) => {
    dispatch(startLoader())

    try {
      const getBookingData = {
        bookingId: linkData.bookingDetails.token_id,
        drawerType: 'edit',
      }
      // if logged in as customer
      if (isAuthenticated()) {
        const response = await dispatch(getBookingDetails(getBookingData))
        if (response && response.payload && response.payload.data && isAuthenticated()) {
          dispatch(openBookingDrawer())
          dispatch(editNewBookingDrawer())
        }
        // if logged out as customer
      } else {
        const formData = {
          ...linkData?.bookingDetails,
          expires: linkData?.expires,
          mailToken: linkData?.token,
        }
        dispatch(setBookingDetailsInGuestFlow(formData))
        if (linkData?.bookingDetails) {
          dispatch(openBookingDrawer())
          dispatch(editNewBookingDrawer())
        }
      }

      // To Do: enable reschedule in guest flow
      dispatch(stopLoader())
    } catch (err: any) {
      dispatch(errorToast(handleMessages(err.message)))
      dispatch(closeDrawer())
      dispatch(resetBookingDetailsInGuestFlow())
      dispatch(stopLoader())
      return rejectWithValue(err)
    }
  },
)

export const confirmRescheduleBookingFromMail = createAsyncThunk(
  '/confirmRescheduleBookingFromMail',
  async (emailLinkDetails: IConfirmRescheduleBookingFromMail, { dispatch, rejectWithValue }) => {
    dispatch(startLoader())
    try {
      const response = await newBookingService.confirmRescheduleBooking(emailLinkDetails)
      dispatch(updateConfirmRescheduleModalStatus(false))
      dispatch(bookingSuccessModalStatus())
      dispatch(stopLoader())
      return response
    } catch (err: any) {
      dispatch(errorToast(handleMessages(err.message)))
      dispatch(closeDrawer())
      dispatch(stopLoader())
      return rejectWithValue(err)
    }
  },
)

export const getSlotsForGuestUser = createAsyncThunk(
  '/getSlotsForGuestUser',
  async (filters: ISlotsFormData, { dispatch, rejectWithValue }) => {
    // dispatch(startLoader())
    try {
      const response = await newBookingService.getSlotsForGuestUser(filters)
      // dispatch(stopLoader())
      return response
    } catch (err: any) {
      // dispatch(stopLoader())
      dispatch(errorToast(handleMessages(err.message)))
      dispatch(setSlotErrorMessage(err.message))
      return rejectWithValue(err)
    }
  },
)

export const checkForFloorsForGuestUser = createAsyncThunk(
  '/checkForFloorsForGuestUser',
  async (params: ICheckForFloors, { dispatch, rejectWithValue }) => {
    try {
      const response = await newBookingService.checkForFloorsForGuestUser(params)
      if (response && response.data.is_seat_booking_available) {
        dispatch(getFloorsForGuestUser(params))
      }
      return response
    } catch (err: any) {
      dispatch(errorToast(handleMessages(err.message)))

      return rejectWithValue(err)
    }
  },
)

export const getFloorsForGuestUser = createAsyncThunk(
  '/getFloorsForGuestUser',
  async (params: IGetFloors, { dispatch, rejectWithValue }) => {
    try {
      const response = await newBookingService.getFloorsForGuestUser(params)
      return response
    } catch (err: any) {
      dispatch(errorToast(handleMessages(err.message)))

      return rejectWithValue(err)
    }
  },
)

export const getCSVSeatsInFloorsForGuestUser = createAsyncThunk(
  '/getCSVSeatsInFloorsForGuestUser',
  async (params: ISeatInFloors, { dispatch, rejectWithValue }) => {
    try {
      const response = await newBookingService.getCSVSeatsInFloorsForGuestUser(params)
      return response
    } catch (err: any) {
      dispatch(errorToast(handleMessages(err.message)))

      return rejectWithValue(err)
    }
  },
)

export const getSVGSeatsInFloorsForGuestUser = createAsyncThunk(
  '/getSVGSeatsInFloorsForGuestUser',
  async (params: ISeatInFloors, { dispatch, rejectWithValue }) => {
    try {
      const response = await newBookingService.getSVGSeatsInFloorsForGuestUser(params)
      return response
    } catch (err: any) {
      dispatch(errorToast(handleMessages(err.message)))
      return rejectWithValue(err)
    }
  },
)

export const checkFloorsAndUpdateSeatDetailsForGuestUser = createAsyncThunk(
  '/checkFloorsAndUpdateSeatDetailsForGuestUser',
  async (bookingDetails: IBookingDetails | Record<string, any>, { dispatch, rejectWithValue }) => {
    const getFloorsParams = {
      facility_id: bookingDetails.facility_id as string,
      master_slot_id: bookingDetails.master_slot_id as number,
      date: bookingDetails.date as string,
      email: bookingDetails?.user_email,
      token_id: bookingDetails.token_id,
    }
    if (bookingDetails.map_details && bookingDetails.floor) {
      dispatch(changeValue({ isLoadingSeats: true }))
    }
    try {
      const response = await dispatch(checkForFloorsForGuestUser(getFloorsParams))

      if (response && response.payload && response.payload.data.is_seat_booking_available) {
        try {
          // for fetching the floor lists
          const response = await dispatch(getFloorsForGuestUser(getFloorsParams))

          if (
            response &&
            response.payload &&
            response.payload.data.floors &&
            bookingDetails.map_details &&
            bookingDetails.floor
          ) {
            const floors = response.payload.data.floors

            if (floors.length > 0) {
              const bookedFloor = floors.find(
                (floor: IFloorsNewBooking) => floor.floor_name === bookingDetails.floor,
              )
              if (bookedFloor && bookingDetails.facility_id && bookingDetails.master_slot_id) {
                const getSeatParam: ISeatInFloors = {
                  floor_id: bookedFloor.floor_id,
                  facility_id: bookingDetails.facility_id,
                  master_slot_id: bookingDetails.master_slot_id,
                  date: bookingDetails.date,
                  no_of_seats: bookingDetails.no_of_seats,
                }

                try {
                  // get floor details
                  // eslint-disable-next-line prefer-const
                  let seats: ISeats[] | [] = []
                  if (bookedFloor.floor_type === 1) {
                    const response = await dispatch(
                      getSVGSeatsInFloorsForGuestUser({
                        ...getSeatParam,
                        email: bookingDetails.user_email,
                      }),
                    )
                    if (response && response.payload && response.payload.data.seats) {
                      seats = response.payload.data.seats
                    }
                  } else {
                    const response = await dispatch(getCSVSeatsInFloorsForGuestUser(getSeatParam))
                    if (response && response.payload && response.payload.data.seats) {
                      seats = response.payload.data.seats
                    }
                  }
                  if (seats) {
                    // get seat names from lockedSeats
                    const bookedSeatsNames = bookingDetails.map_details.lockedSeats.map(
                      (seatObj: ILockedSeats) => seatObj.seat_name,
                    )
                    const bookedSeats = seats.filter((seat: ISeats) =>
                      bookedSeatsNames.includes(seat.seat_name),
                    )
                    const statusUpdatedBookedSeats = bookedSeats.map((seat: ISeats) => {
                      return { ...seat, status: 1 }
                    })
                    dispatch(updateSelectedSeats(statusUpdatedBookedSeats))
                  }
                } catch (err: any) {
                  dispatch(stopLoader())
                  dispatch(errorToast(handleMessages(err.message)))
                  return rejectWithValue(err)
                }
              }
            }
          }
        } catch (err: any) {
          dispatch(stopLoader())
          dispatch(errorToast(handleMessages(err.message)))
          return rejectWithValue(err)
        }
      }

      return response
    } catch (err: any) {
      dispatch(errorToast(handleMessages(err.message)))

      return rejectWithValue(err)
    }
  },
)

export const blockSeatsForGuestUser = createAsyncThunk(
  '/blockSeatsForGuestUser',
  async (params: IBlockSeats, { dispatch, rejectWithValue }) => {
    dispatch(startLoader())
    dispatch(updateFloorNotAvailable(false))
    try {
      const response = await newBookingService.blockSeatsForGuestUser(params)
      dispatch(stopLoader())
      if (params.isBookForOthers && params.userEmails) {
        // get unregistered emails from the userEmails before opening the confirmation modal
        dispatch(validateEmails({ user_emails: params.userEmails }))
      } else {
        dispatch(updateBookingConfirmationModalStatus(true))
      }
      return response
    } catch (err: any) {
      if ('floorNotAvailable' in err.data && err.data.floorNotAvailable) {
        dispatch(updateFloorNotAvailable(err.data.floorNotAvailable))
        dispatch(updateNotBlockedSeats([]))
        dispatch(updatePreviouslyBlockedSeats([]))
      } else {
        dispatch(
          updateNotBlockedSeats(
            err.data.not_blocked_seats?.map((seat: any) => ({
              seat_id: seat.seat_id,
            })),
          ),
        )
        dispatch(
          updatePreviouslyBlockedSeats(
            err.data.blocked_seats?.map((seat: any) => ({
              seat_id: seat.seat_id,
            })),
          ),
        )
      }
      dispatch(stopLoader())
      dispatch(errorToast(handleMessages(err.message)))
      return rejectWithValue(err)
    }
  },
)

export const unBlockSeatsForGuestUser = createAsyncThunk(
  '/unBlockSeatsForGuestUser',
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  async (params: IBlockSeats, { dispatch, rejectWithValue }) => {
    try {
      const response = await newBookingService.blockSeatsForGuestUser(params)
      return response
    } catch (err: any) {
      // dispatch(errorToast(handleMessages(err.message)))
      return rejectWithValue(err)
    }
  },
)

export const validatePhoneNumberForGuestUser = createAsyncThunk(
  '/validatePhoneNumberForGuestUser',
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  async (params: IGuestUserDetails, { dispatch, rejectWithValue }) => {
    try {
      const response = await newBookingService.validatePhoneNumberForGuestUser(params)

      return response
    } catch (err: any) {
      if (err && err.message === 'Email address is registered with a different phone number.') {
        dispatch(changeValue({ isDifferentPhoneNumber: true }))
      }
      if (err && err.message === 'Phone number is already associated with a user.') {
        dispatch(changeValue({ isDuplicatePhoneNumber: true }))
      }
      if (err && err?.message?.phone[0]) {
        dispatch(setPhoneValidationMsg(err.message.phone[0]))
      }
      return rejectWithValue(err)
    }
  },
)

export const newBookingForGuestUser = createAsyncThunk(
  '/newBookingForGuestUser',
  async (formData: any, { dispatch, rejectWithValue }) => {
    dispatch(startLoader())
    try {
      const response = await newBookingService.newBookingForGuestUser(formData)
      dispatch(stopLoader())
      dispatch(updatePreviouslyBlockedSeats([]))
      dispatch(bookingSuccessModalStatus())
      dispatch(closeDrawer())
      return response
    } catch (err: any) {
      if ('floorNotAvailable' in err.data && err.data.floorNotAvailable) {
        dispatch(updateFloorNotAvailable(err.data.floorNotAvailable))
        dispatch(updateNotBlockedSeats([]))
        dispatch(updatePreviouslyBlockedSeats([]))
      }
      dispatch(stopLoader())
      dispatch(errorToast(handleMessages(err.message)))
      return rejectWithValue(err)
    }
  },
)

export const editBookingForGuestUser = createAsyncThunk(
  '/editBookingForGuestUser ',
  async (formData: any, { dispatch, rejectWithValue }) => {
    dispatch(startLoader())
    try {
      const response = await newBookingService.editBookingForGuestUser(formData)
      dispatch(stopLoader())
      dispatch(closeDrawer())
      dispatch(bookingSuccessModalStatus())
      if (response.status === 'success' && isAuthenticated()) {
        History.navigate('/customer/my-bookings')
      }
      return response
    } catch (err: any) {
      if (err.token_status === 'expired') {
        dispatch(stopLoader())
        History.navigate('/link-expired')
      } else {
        if ('floorNotAvailable' in err.data && err.data.floorNotAvailable) {
          dispatch(updateFloorNotAvailable(err.data.floorNotAvailable))
          dispatch(updateNotBlockedSeats([]))
          dispatch(updatePreviouslyBlockedSeats([]))
        }
        dispatch(stopLoader())
        dispatch(errorToast(handleMessages(err.message)))
      }
      return rejectWithValue(err)
    }
  },
)

export const newBookingSlice = createSlice({
  name: 'newBooking',
  initialState,
  reducers: {
    bookingDetailsDrawer: (state) => {
      // state.drawerStatus = true
      state.drawerType = 'details'
    },
    editNewBookingDrawer: (state) => {
      // state.drawerMode = true
      state.drawerType = 'edit'
      state.noActiveToken = false
    },
    createNewBookingDrawer: (state) => {
      state.drawerMode = true
      state.drawerType = 'create'
      state.noActiveToken = false
    },
    drawerStatusChange: (state) => {
      state.drawerStatus = false
      state.drawerMode = false
      // state.drawerType = 'create'
    },
    resetBookingDetails: (state) => {
      state.bookingDetails = initialState.bookingDetails
    },
    closeDrawer: (state) => {
      state.drawerMode = false
      state.bookingDetails = {}
      state.phoneNumberInformation = initialState.phoneNumberInformation
      state.drawerType = ''
      state.drawerStatus = false
      state.floors = initialState.floors
      state.seatAvailable = initialState.seatAvailable
      state.isExactTiming = initialState.isExactTiming
      state.seatBookingNotAvailableReason = initialState.seatBookingNotAvailableReason
      state.customerFacilityData = initialState.customerFacilityData
      state.isRegisteredMail = initialState.isRegisteredMail
      state.isFloorMapLoaded = initialState.isFloorMapLoaded
      state.isDifferentPhoneNumber = false
      state.isDuplicatePhoneNumber = false
    },
    openDetailsDrawer: (state) => {
      state.drawerStatus = true
      // state.drawerMode = false
    },
    closeDetailsDrawer: (state) => {
      state.drawerStatus = false
      // state.drawerMode = false
    },
    openBookingDrawer: (state) => {
      state.drawerMode = true
    },
    openCustomerNewBooking: (state, action) => {
      state.drawerMode = true
      state.drawerType = 'create'
      state.customerFacilityData = action.payload
    },
    cancelBookingModal: (state) => {
      state.cancelModal = true
      state.tokenIds = []
    },
    closeCancelBookingModal: (state) => {
      state.cancelModal = false
      state.tokenIds = []
    },

    bookingSuccessModalStatus: (state) => {
      state.bookingSuccessModalStatus = true
    },
    closeBookingSuccessModal: (state) => {
      state.bookingSuccessModalStatus = false
    },
    setLocationLoadingStatusTrue: (state) => {
      state.isLoadingLocations = true
    },
    setLocationLoadingStatusFalse: (state) => {
      state.isLoadingLocations = false
    },
    setFacilityLoadingStatusTrue: (state) => {
      state.isLoadingFacilities = true
    },
    setFacilityLoadingStatusFalse: (state) => {
      state.isLoadingFacilities = false
    },
    updateBookingConfirmationModalStatus: (state, action) => {
      return {
        ...state,
        bookingConfirmationModalStatus: action.payload,
      }
    },
    noActiveTokenStatus: (state) => {
      state.drawerMode = true
      state.drawerType = 'create'
      state.noActiveToken = true
      state.phoneNumberInformation = initialState.phoneNumberInformation
    },
    resetFloorDetails: (state) => {
      state.floorDetails = initialState.floorDetails
    },
    updateFloorDetails: (state, action) => {
      return {
        ...state,
        floorDetails: action.payload,
      }
    },
    updateSelectedSeats: (state, action) => {
      return {
        ...state,
        selectedSeats: action.payload,
      }
    },
    updatePreviouslyBlockedSeats: (state, action) => {
      return {
        ...state,
        previouslyBlockedSeats: action.payload,
      }
    },
    updateNotBlockedSeats: (state, action) => {
      return {
        ...state,
        notBlockedSeats: action.payload,
      }
    },
    updateFloorNotAvailable: (state, action) => {
      return {
        ...state,
        floorNotAvailable: action.payload,
      }
    },
    changeValue: (state, action) => {
      return {
        ...state,
        ...action.payload,
      }
    },
    resetSeatAvailabilityStates: (state) => {
      state.seatAvailable = initialState.seatAvailable
      state.isExactTiming = initialState.isExactTiming
      state.seatBookingNotAvailableReason = initialState.seatBookingNotAvailableReason
    },
    updateSelectedSeatsWithUserData: (state, action) => {
      return {
        ...state,
        selectedSeatWithUserData: action.payload,
      }
    },
    updateIsBookForOthers: (state, action) => {
      return {
        ...state,
        isBookForOthers: action.payload,
      }
    },
    resetPreviousBookingInfo: (state) => {
      state.showPreviousSuggestion = false
      state.previousBookingInfo = {}
    },
    hidePreviousSuggestion: (state) => {
      state.showPreviousSuggestion = false
    },
    updateConfirmRescheduleModalStatus: (state, action) => {
      return {
        ...state,
        isConfirmRescheduleModalOpen: action.payload,
      }
    },
    setBookingDetailsInGuestFlow: (state, action) => {
      state.bookingDetailsGuest = action.payload
    },
    resetBookingDetailsInGuestFlow: (state) => {
      state.bookingDetailsGuest = initialState.bookingDetailsGuest
    },
    setPhoneValidationMsg: (state, action) => {
      state.phoneValidationMsg = action.payload
    },
    setSlotErrorMessage: (state, action) => {
      state.slotInformationMessage = action.payload
    },
  },
  extraReducers: (builder) => {
    builder
      // newBooking Details
      .addCase(getLocationsNewBooking.fulfilled, (state, action) => {
        state.newBookingLocationInformation = action.payload.data.locations
      })
      .addCase(getLocationsNewBooking.rejected, (state) => {
        state.newBookingLocationInformation = initialState.newBookingLocationInformation
      })
      .addCase(getPhoneNumber.fulfilled, (state, action) => {
        state.phoneNumberInformation = action.payload.data
        state.isRegisteredMail = true
        state.phoneResponseLoading = false
      })
      .addCase(getPhoneNumber.rejected, (state) => {
        state.isRegisteredMail = false
        state.phoneNumberInformation = initialState.phoneNumberInformation
        state.phoneResponseLoading = false
      })
      .addCase(getPhoneNumber.pending, (state) => {
        state.phoneResponseLoading = true
      })
      .addCase(getSlots.pending, (state) => {
        state.isLoadingSlots = true
      })
      .addCase(getSlotsForGuestUser.pending, (state) => {
        state.isLoadingSlots = true
      })
      .addCase(getSlots.fulfilled, (state, action) => {
        state.slotInformation = action.payload.data
        state.slotInformationMessage = action.payload.message
        state.isLoadingSlots = false
      })
      .addCase(getSlotsForGuestUser.fulfilled, (state, action) => {
        state.slotInformation = action.payload.data
        state.slotInformationMessage = action.payload.message
        state.isLoadingSlots = false
      })
      .addCase(getSlots.rejected, (state) => {
        state.isLoadingSlots = false
        state.slotInformation = initialState.slotInformation
      })
      .addCase(getSlotsForGuestUser.rejected, (state) => {
        state.isLoadingSlots = false
        state.slotInformation = initialState.slotInformation
      })
      .addCase(getBookingDetails.pending, (state) => {
        state.isLoadingbookingDetails = true
      })
      .addCase(getBookingDetails.fulfilled, (state, action) => {
        state.bookingDetails = action.payload.data.token
        state.isLoadingbookingDetails = false
      })
      .addCase(getBookingDetails.rejected, (state) => {
        state.isLoadingbookingDetails = false
        state.drawerStatus = false
      })
      .addCase(cancelBooking.fulfilled, (state, action) => {
        state.tokenIds = action.payload.data.token
      })
      .addCase(newBooking.fulfilled, (state, action) => {
        state.bookingSuccessInformation = action.payload.data
        state.bookingSuccessHeaderMessage = action.payload.message
      })
      .addCase(checkForFloors.fulfilled, (state, action) => {
        state.seatAvailable = action.payload.data.is_seat_booking_available
        if (action.payload.data.is_exact_timing) {
          state.isExactTiming = action.payload.data.is_exact_timing
        }
        state.seatBookingNotAvailableReason = action.payload.message
      })
      .addCase(checkForFloorsForGuestUser.fulfilled, (state, action) => {
        state.seatAvailable = action.payload.data.is_seat_booking_available
        if (action.payload.data.is_exact_timing) {
          state.isExactTiming = action.payload.data.is_exact_timing
        }
        state.seatBookingNotAvailableReason = action.payload.message
      })
      .addCase(getFloors.fulfilled, (state, action) => {
        state.floors = action.payload.data.floors
        state.isLoadingFloors = false
      })
      .addCase(getFloorsForGuestUser.fulfilled, (state, action) => {
        state.floors = action.payload.data.floors
        state.isLoadingFloors = false
      })
      .addCase(getFloors.pending, (state) => {
        state.isLoadingFloors = true
      })
      .addCase(getFloorsForGuestUser.pending, (state) => {
        state.isLoadingFloors = true
      })
      .addCase(getFloors.rejected, (state) => {
        state.isLoadingFloors = false
      })
      .addCase(getFloorsForGuestUser.rejected, (state) => {
        state.isLoadingFloors = false
      })
      .addCase(getCSVSeatsInFloors.pending, (state) => {
        state.isLoadingSeats = true
        state.isFloorMapLoaded = false
      })
      .addCase(getCSVSeatsInFloorsForGuestUser.pending, (state) => {
        state.isLoadingSeats = true
      })
      .addCase(getSVGSeatsInFloors.pending, (state) => {
        state.isLoadingSeats = true
        state.isFloorMapLoaded = false
      })
      .addCase(getSVGSeatsInFloorsForGuestUser.pending, (state) => {
        state.isLoadingSeats = true
      })
      .addCase(getCSVSeatsInFloors.fulfilled, (state, action) => {
        state.floorDetails = {
          ...action.payload.data,
          seats:
            state.drawerType === 'create'
              ? action.payload.data.seats
              : getBookingsUpdatedSeats(
                  action.payload.data.seats,
                  state.bookingDetails.map_details,
                ),
          availableSeats:
            state.drawerType === 'create'
              ? action.payload.data.availableSeats
              : getBookingsUpdatedAvailableSeatsCount(
                  action.payload.data.availableSeats,
                  state.bookingDetails.map_details,
                ),
        }
        state.isLoadingSeats = false
        state.isFloorMapLoaded = true
      })
      .addCase(getCSVSeatsInFloorsForGuestUser.fulfilled, (state, action) => {
        state.floorDetails = {
          ...action.payload.data,
          seats:
            state.drawerType === 'create'
              ? action.payload.data.seats
              : getBookingsUpdatedSeats(
                  action.payload.data.seats,
                  state.bookingDetailsGuest.map_details,
                ),
          availableSeats:
            state.drawerType === 'create'
              ? action.payload.data.availableSeats
              : getBookingsUpdatedAvailableSeatsCount(
                  action.payload.data.availableSeats,
                  state.bookingDetailsGuest.map_details,
                ),
        }
        state.isLoadingSeats = false
      })
      .addCase(getCSVSeatsInFloors.rejected, (state) => {
        state.isLoadingSeats = false
        state.isFloorMapLoaded = false
      })
      .addCase(getCSVSeatsInFloorsForGuestUser.rejected, (state) => {
        state.isLoadingSeats = false
      })
      .addCase(blockSeats.fulfilled, (state, action) => {
        state.previouslyBlockedSeats = action.payload.data?.blocked_seats?.map((seat: any) => ({
          seat_id: seat.seat_id,
        }))
      })
      .addCase(blockSeatsForGuestUser.fulfilled, (state, action) => {
        state.previouslyBlockedSeats = action.payload.data?.blocked_seats?.map((seat: any) => ({
          seat_id: seat.seat_id,
        }))
      })
      .addCase(getSVGSeatsInFloors.fulfilled, (state, action) => {
        state.floorDetails = {
          ...action.payload.data,
          seats:
            state.drawerType === 'create'
              ? action.payload.data.seats
              : getBookingsUpdatedSeats(
                  action.payload.data.seats,
                  state.bookingDetails.map_details,
                ),
          availableSeats:
            state.drawerType === 'create'
              ? action.payload.data.availableSeats
              : getBookingsUpdatedAvailableSeatsCount(
                  action.payload.data.availableSeats,
                  state.bookingDetails.map_details,
                ),
        }
        state.isLoadingSeats = false
        state.isFloorMapLoaded = true
      })
      .addCase(getSVGSeatsInFloorsForGuestUser.fulfilled, (state, action) => {
        state.floorDetails = {
          ...action.payload.data,
          seats:
            state.drawerType === 'create'
              ? action.payload.data.seats
              : getBookingsUpdatedSeats(
                  action.payload.data.seats,
                  state.bookingDetailsGuest.map_details,
                ),
          availableSeats:
            state.drawerType === 'create'
              ? action.payload.data.availableSeats
              : getBookingsUpdatedAvailableSeatsCount(
                  action.payload.data.availableSeats,
                  state.bookingDetailsGuest.map_details,
                ),
        }
        state.isLoadingSeats = false
      })
      .addCase(getSVGSeatsInFloors.rejected, (state) => {
        state.isLoadingSeats = false
        state.isFloorMapLoaded = false
      })
      .addCase(getSVGSeatsInFloorsForGuestUser.rejected, (state) => {
        state.isLoadingSeats = false
      })
      .addCase(previousBooking.fulfilled, (state, action) => {
        state.previousBookingInfo = action?.payload?.data?.data
        state.showPreviousSuggestion = true
      })
      .addCase(previousBooking.rejected, (state) => {
        state.previousBookingInfo = initialState.previousBookingInfo
        state.showPreviousSuggestion = false
      })
      .addCase(checkCancelBookingMailLink.pending, (state) => {
        state.isValidCancelBookingMailLink = false
        state.checkCancelBookingDetails = {}
      })
      .addCase(checkCancelBookingMailLink.fulfilled, (state, action) => {
        state.isValidCancelBookingMailLink = true
        state.checkCancelBookingDetails = action?.payload?.data
      })
      .addCase(cancelBookingFromMailLink.fulfilled, (state, action) => {
        state.cancelBookingDetails = action?.payload?.data
      })
      .addCase(cancelBookingFromMailLink.pending, (state) => {
        state.cancelBookingDetails = {}
      })
      .addCase(checkRescheduleBookingMailLink.pending, (state) => {
        state.isValidRescheduleBookingMailLink = false
        state.rescheduleBookingDetails = {}
      })
      .addCase(checkRescheduleBookingMailLink.fulfilled, (state, action) => {
        state.isValidRescheduleBookingMailLink = true
        state.rescheduleBookingDetails = action?.payload?.data?.token
      })
      .addCase(checkConfirmRescheduleBookingMailLink.pending, (state) => {
        state.isValidConfirmRescheduleLink = false
        state.confirmRescheduleDetails = {}
      })
      .addCase(checkConfirmRescheduleBookingMailLink.fulfilled, (state, action) => {
        state.isValidConfirmRescheduleLink = true
        state.confirmRescheduleDetails = action?.payload?.data
      })
      .addCase(updateBooking.fulfilled, (state, action) => {
        state.bookingSuccessInformation = action.payload.data
        state.bookingSuccessHeaderMessage = action.payload.message
      })
      .addCase(confirmRescheduleBookingFromMail.fulfilled, (state, action) => {
        state.bookingSuccessInformation = action.payload.data[0]
        state.bookingSuccessHeaderMessage = action.payload.message
      })
      .addCase(validateEmails.fulfilled, (state, action) => {
        state.unregisteredEmails = action.payload.data?.new_emails
      })
      .addCase(newBookingForGuestUser.fulfilled, (state, action) => {
        state.bookingSuccessInformation = action.payload.data
        state.bookingSuccessHeaderMessage = action.payload.message
      })
      .addCase(validatePhoneNumberForGuestUser.pending, (state) => {
        state.isDifferentPhoneNumber = false
        state.isDuplicatePhoneNumber = false
        state.phoneValidationMsg = ''
      })
      .addCase(validatePhoneNumberForGuestUser.fulfilled, (state) => {
        state.isDifferentPhoneNumber = false
        state.isDuplicatePhoneNumber = false
        state.phoneValidationMsg = ''
      })
      .addCase(editBookingForGuestUser.fulfilled, (state, action) => {
        state.bookingSuccessInformation = action.payload.data
        state.bookingSuccessHeaderMessage = action.payload.message
      })
  },
})
export const {
  bookingDetailsDrawer,
  drawerStatusChange,
  setSlotErrorMessage,
  createNewBookingDrawer,
  editNewBookingDrawer,
  resetBookingDetails,
  closeDrawer,
  cancelBookingModal,
  closeCancelBookingModal,
  openDetailsDrawer,
  openBookingDrawer,
  bookingSuccessModalStatus,
  closeBookingSuccessModal,
  setLocationLoadingStatusTrue,
  setLocationLoadingStatusFalse,
  setFacilityLoadingStatusTrue,
  setFacilityLoadingStatusFalse,
  updateBookingConfirmationModalStatus,
  noActiveTokenStatus,
  resetFloorDetails,
  updateSelectedSeats,
  updatePreviouslyBlockedSeats,
  updateNotBlockedSeats,
  updateFloorDetails,
  changeValue,
  resetSeatAvailabilityStates,
  updateFloorNotAvailable,
  openCustomerNewBooking,
  updateSelectedSeatsWithUserData,
  updateIsBookForOthers,
  hidePreviousSuggestion,
  resetPreviousBookingInfo,
  updateConfirmRescheduleModalStatus,
  setBookingDetailsInGuestFlow,
  resetBookingDetailsInGuestFlow,
  setPhoneValidationMsg,
} = newBookingSlice.actions

export const newBookingData = (state: RootState) => state.newBooking
const newBookingReducer = newBookingSlice.reducer
export default newBookingReducer
