import { IIcon } from './iconConstants'
const Close3Icon = ({ width, height, fill }: IIcon) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 22 22'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M10.999 20.167a9.167 9.167 0 1 1 0-18.334 9.167 9.167 0 0 1 0 18.334zm0-1.834a7.333 7.333 0 1 0 0-14.666 7.333 7.333 0 0 0 0 14.667zm0-8.63 2.592-2.592 1.297 1.296L12.295 11l2.593 2.593-1.297 1.296L11 12.297l-2.593 2.592-1.296-1.296L9.702 11 7.11 8.407l1.296-1.296 2.593 2.593z'
        fill={fill}
      />
    </svg>
  )
}
export default Close3Icon
