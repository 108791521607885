import CloseIcon from '@mui/icons-material/Close'
import {
  Box,
  Button,
  ClickAwayListener,
  Grid,
  IconButton,
  InputAdornment,
  Link,
  Tab,
  TabProps,
  Tabs,
  TabsProps,
  TextField,
  Typography,
  styled,
  useTheme,
} from '@mui/material'
import moment from 'moment'
import React, { useState } from 'react'
import { useAppDispatch, useAppSelector } from '../../../_helpers/hooks'
import CustomModal from '../../../components/@extended/modal'
import PricingCards from '../../../features/pricingCards'
import { closePricingPage, pricingData, resetMailValidStatus } from '../../../slices/pricingSlice'

import { logout } from '../../../slices/authenticationSlice'

import LogoIcon from '../../../images/logo.svg'

import { deleteAccount, userState } from '../../../slices/userSlice'

import { VisibilityOffOutlined, VisibilityOutlined } from '@mui/icons-material'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import { PERMISSIONS, PLAN } from '../../../_constants'
import { CustomScrollbarBox } from '../../../components/@extended/customScrollbar'
import {
  DeleteIcon,
  LogoutBoxRLineIcon,
  SwitchUserIcon,
  WarningIcon,
} from '../../../components/icons'
import ActionAlertModal from '../../../features/actionAlertModal'
import { useUserPermissionAuthorization } from '../../../hooks'
import { switchProfile } from '../../../slices/businessSlice'
interface TabPanelProps {
  children?: React.ReactNode
  index: number
  value: number
}
type FormInputs = {
  modalPassword: string
}

const CustomTabs = styled(Tabs)<TabsProps>(({ theme }) => ({
  backgroundColor: theme.palette.label.main,
  borderRadius: '0.75rem',
  padding: '0.3rem',
}))

const CustomTab = styled(Tab)<TabProps>(({ theme }) => ({
  color: theme.palette.label.light,
  borderRadius: '0.75rem',
  fontSize: '0.938rem',
  fontWeight: '600',
  '&.Mui-selected': {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.description.light,
    borderBottom: 'none',
  },
}))

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography component='div'>{children}</Typography>
        </Box>
      )}
    </div>
  )
}
function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  }
}

const PricingPage = () => {
  const [value, setValue] = React.useState(0)
  const pricingState = useAppSelector(pricingData)
  const translatedInput = useAppSelector((state) => state?.translation.translatedData)

  const {
    isOpen,
    pricingInformation,
    closable,
    subscriptionPlanDetails,
    pricingPageOrigin,
    paymentDetails,
    subscriptionName,
    subscriptionEndDate,
  } = pricingState
  const time =
    `${translatedInput?.subscriptionTxt?.subscriptionPlanLabel} - ` +
    subscriptionPlanDetails?.subscription?.name +
    ` (${translatedInput?.subscriptionTxt?.subscriptionExpiryOnLabel} ` +
    moment(subscriptionPlanDetails?.subscription?.ends_at).format('DD MMMM YYYY ') +
    ')'

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue)
  }
  const dispatch = useAppDispatch()
  const [isPermitted] = useUserPermissionAuthorization()
  const { activePlan, userData } = useAppSelector(userState)

  const expiredTime =
    `${translatedInput?.subscriptionTxt?.yourPlanText} - ` +
    subscriptionName +
    ` (${translatedInput?.subscriptionTxt?.expiredOnText} ` +
    moment(subscriptionEndDate).format('DD MMMM YYYY ') +
    ')'

  const handleClose = () => {
    dispatch(closePricingPage())
    dispatch(resetMailValidStatus())
    setValue(0) // Reset to monthly plan whenever the pricing modal closes
  }

  const handleLogout = () => {
    handleClose()
    dispatch(logout())
  }

  // User upgrading to new plan can close the pricing modal. Upgrading users modal content is different.

  const [hidePassword, setHidePassword] = useState(false)
  const [deleteAlertModal, setDeleteAlertModal] = useState<boolean>(false)
  const [isDeleteConfirmed, setIsDeleteConfirmed] = useState<boolean>(false)
  const theme = useTheme()
  const { handleSubmit, control, getValues, reset } = useForm<FormInputs>()

  const onDeleteAlertModalSubmit: SubmitHandler<FormInputs> = () => {
    updateDeleteAlertModal()
  }

  const handleClickShowPasswordDelete = () => {
    setHidePassword((prevValue) => !prevValue)
  }

  const handleDelete = () => {
    setDeleteAlertModal(true)
  }
  const closeDeleteAlertModal = (): void => {
    if (isDeleteConfirmed) {
      resetDeleteAlertModal()
    } else {
      resetDeleteAlertModal()
    }
  }
  const updateDeleteAlertModal = (): void => {
    if (isDeleteConfirmed) {
      const formData = getValues().modalPassword
      if (formData) {
        const modifiedFormData = {
          password: formData,
        }
        dispatch(deleteAccount(modifiedFormData))
        resetDeleteAlertModal()
        setHidePassword(false)
      }
    } else {
      setIsDeleteConfirmed(true)
    }
  }
  const resetDeleteAlertModal = (): void => {
    setDeleteAlertModal(false)
    setIsDeleteConfirmed(false)
    reset()
  }
  const handleSwitchToPersonal = (profileType: string) => {
    if (profileType === 'Customer' && !closable) {
      dispatch(closePricingPage())
      setValue(0) // Reset to monthly plan whenever the modal closes
    }
    dispatch(switchProfile({ type: profileType }))
  }
  const handleClickAway = () => {
    if (closable) {
      handleClose()
    }
  }

  const deleteAlertModalButtons = (secondaryBtnText: string) => (
    <Box pt={'1.25rem'} width={'100%'}>
      <Grid container spacing={'1.25rem'}>
        <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
          <Button
            sx={{ width: '100%' }}
            variant='contained'
            size='large'
            onClick={() => {
              if (!isDeleteConfirmed) {
                updateDeleteAlertModal()
              }
            }}
            type='submit'
          >
            {secondaryBtnText}
          </Button>
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
          <Button
            sx={{ width: '100%' }}
            variant='outlined'
            size='large'
            onClick={closeDeleteAlertModal}
          >
            {translatedInput?.common?.cancelBtn}
          </Button>
        </Grid>
      </Grid>
    </Box>
  )

  const deleteAlertModalContent1 = (
    <Box p={'0.625rem'}>
      <Typography variant='subtitle1' color='secondary.main' textAlign='center'>
        {translatedInput?.common?.deleteAccountText1}
      </Typography>

      <Typography
        variant='subtitle1'
        color='secondary.main'
        mb={'1.375rem'}
        width={'26.125rem'}
        textAlign='center'
      >
        {translatedInput?.common?.deleteAccountText4}
      </Typography>

      {deleteAlertModalButtons(translatedInput?.accountSettings?.deleteMyAccount)}
    </Box>
  )

  const trialPlanIdAray = [
    ...(pricingInformation.monthly_plans ?? []),
    ...(pricingInformation.yearly_plans ?? []),
  ].flatMap((plan) => (plan.trial_plan_id ? [plan.trial_plan_id] : []))

  const headingText = () => {
    if (closable) {
      if (
        subscriptionPlanDetails?.subscription?.slug === PLAN.BASIC_TRIAL ||
        subscriptionPlanDetails?.subscription?.slug === PLAN.PRO_TRIAL
      ) {
        // Account with active subscription plan
        return translatedInput?.pricingPage?.upgradeSubscriptionTitle
      } else if (pricingPageOrigin !== 'alertCard') {
        return translatedInput?.pricingPage?.upgradeSubscriptionTitle
      } else {
        return translatedInput?.pricingPage?.renewSubscriptionTitle
      }
    } else {
      if (trialPlanIdAray.length > 0) {
        // Newly signed in account before choosing a subscription plan
        return `${translatedInput?.pricing?.simplePricing} ${translatedInput?.pricing?.everyone}`
      } else if (
        // Account with expired subscription plan
        userData?.subscription === null ||
        (subscriptionEndDate && subscriptionEndDate !== '')
      ) {
        return translatedInput?.pricingPage?.upgradeSubscriptionTitle
      } else if (
        // Deleted account signed up again
        (userData?.user_was_deleted && !userData?.can_activate_trial) ||
        // after payment failure
        (subscriptionPlanDetails?.user_was_deleted &&
          !subscriptionPlanDetails?.can_activate_trail_plan)
      ) {
        return `${translatedInput?.pricing?.simplePricing} ${translatedInput?.pricing?.everyone}`
      }
    }
  }

  const descriptionText = () => {
    if (closable) {
      // Account with active subscription plan
      if (subscriptionPlanDetails?.is_grace_period) {
        return expiredTime
      } else {
        return time
      }
    } else if (trialPlanIdAray.length > 0) {
      // Newly signed in account before choosing a subscription plan
      return translatedInput?.pricing?.pricingSubHeading
    } else if (
      // Account with expired subscription plan
      userData?.subscription === null ||
      (subscriptionEndDate && subscriptionEndDate !== '')
    ) {
      return expiredTime
    } else if (
      // Deleted account signed up again
      (userData?.user_was_deleted && !userData?.can_activate_trial) ||
      // after payment failure
      (subscriptionPlanDetails?.user_was_deleted &&
        !subscriptionPlanDetails?.can_activate_trail_plan)
    ) {
      return `${translatedInput?.pricing?.pricingDescription1} ${
        userData?.email || paymentDetails?.email
      } ${translatedInput?.pricing?.pricingDescription2} ${
        translatedInput?.pricing?.pricingDescription3
      }`
    }
  }
  const deleteAlertModalContent2 = (
    <Box p={'0.625rem'} width={'100%'}>
      <Typography variant='subtitle1' color='secondary.main' mb={'0.625rem'} textAlign='center'>
        {translatedInput?.common?.deleteAccountText2}
      </Typography>

      <Box width={'100%'}>
        <form onSubmit={handleSubmit(onDeleteAlertModalSubmit)}>
          <Controller
            name='modalPassword'
            control={control}
            defaultValue=''
            rules={{
              required: translatedInput?.common?.passwordRequired,
            }}
            render={({ field, fieldState: { error } }) => (
              <TextField
                {...field}
                margin='normal'
                type={!hidePassword ? 'password' : 'text'}
                fullWidth
                size='medium'
                label={translatedInput?.common?.passwordLabel}
                error={!!error}
                helperText={error ? error.message : null}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position='end'>
                      <IconButton
                        aria-label='toggle delete password visibility '
                        onClick={handleClickShowPasswordDelete}
                        edge='start'
                        sx={{ color: 'secondary.light' }}
                      >
                        {hidePassword ? <VisibilityOutlined /> : <VisibilityOffOutlined />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            )}
          />
          {deleteAlertModalButtons(translatedInput?.accountSettings?.yesDeleteBtn)}
        </form>
      </Box>
    </Box>
  )
  return (
    <CustomModal
      isOpen={isOpen}
      onClose={
        closable
          ? handleClose
          : () => {
              return
            }
      }
      paddingRightRequired
    >
      <CustomScrollbarBox
        height={'80vh'}
        sx={{
          overflowY: 'auto',
          '&::-webkit-scrollbar:horizontal': {
            display: 'none',
          },
          pt: 3,
          pr: 3,
        }}
      >
        <ClickAwayListener onClickAway={handleClickAway}>
          <Box sx={{ minWidth: '45vw' }}>
            {closable && (
              <Box display='flex' justifyContent='end' m={-4}>
                <Button onClick={handleClose}>
                  <CloseIcon sx={{ color: 'black' }} />
                </Button>
              </Box>
            )}
            {/* {!closable && (
          <Box display='flex' justifyContent='start' ml={2}>
            <Box component='img' src={LogoIcon} />
          </Box>
        )} */}
            <Box display='flex' justifyContent='start' ml={2} mt={1}>
              <Box component='img' src={LogoIcon} />
            </Box>
            <Box display='flex' flexDirection='column' alignItems='center'>
              <Typography
                variant='h2'
                fontSize='1.875rem'
                color='common.black'
                sx={{ mb: 3, mt: 3 }}
                align='center'
              >
                {headingText()}
              </Typography>
              <Typography
                variant='subtitle2'
                display='block'
                color='secondary.main'
                align='center'
                fontSize='1rem'
                lineHeight='1.44'
                maxWidth='45rem'
              >
                {descriptionText()}
              </Typography>
            </Box>

            <Box mt={'1.875rem'}>
              <Box justifyContent='center' width='auto' display='flex' mb={'1.25rem'}>
                <CustomTabs
                  value={value}
                  onChange={handleChange}
                  aria-label='basic tabs example'
                  centered
                  TabIndicatorProps={{
                    style: {
                      display: 'none',
                    },
                  }}
                >
                  <CustomTab
                    label={translatedInput?.common?.planLabelMonthly}
                    {...a11yProps(0)}
                    onClick={() => setValue(0)}
                  />
                  <CustomTab
                    label={translatedInput?.common?.planLabelYearly}
                    {...a11yProps(1)}
                    onClick={() => setValue(1)}
                  />
                </CustomTabs>
              </Box>
              <TabPanel value={value} index={0}>
                <PricingCards
                  pricingDataInfo={pricingInformation?.monthly_plans}
                  canActivateTrial={subscriptionPlanDetails?.can_activate_trail_plan}
                />
              </TabPanel>
              <TabPanel value={value} index={1}>
                <PricingCards
                  pricingDataInfo={pricingInformation?.yearly_plans}
                  canActivateTrial={subscriptionPlanDetails?.can_activate_trail_plan}
                />
              </TabPanel>

              {!closable && (
                <Box
                  display={{ xs: 'block', sm: 'flex' }}
                  gap={{ xs: '0', sm: '0.6rem' }}
                  justifyContent={'center'}
                >
                  <Box display={'flex'} alignItems={'center'} justifyContent={'center'}>
                    <IconButton
                      sx={{ mr: '0.188rem' }}
                      onClick={() => {
                        handleLogout()
                      }}
                    >
                      <Box sx={{ height: '1.25rem', width: '1.25rem' }}>
                        <LogoutBoxRLineIcon
                          width={'17'}
                          height={'18'}
                          fill={theme.palette.error.main}
                        />
                      </Box>
                    </IconButton>
                    <Link
                      onClick={() => {
                        handleLogout()
                      }}
                    >
                      <Typography
                        variant='subtitle1'
                        color='error.main'
                        fontWeight='normal'
                        lineHeight='normal'
                        marginTop='0.5rem'
                      >
                        {translatedInput?.generalText?.signOutText}
                      </Typography>
                    </Link>
                  </Box>
                  {activePlan === false && isPermitted([PERMISSIONS.SUBSCRIPTION_ADD]) && (
                    <Box display={'flex'} alignItems={'center'} justifyContent={'center'}>
                      <IconButton
                        sx={{ mr: '0.188rem' }}
                        onClick={() => {
                          handleSwitchToPersonal('Customer')
                        }}
                      >
                        <Box sx={{ height: '1.25rem', width: '1.25rem' }}>
                          <SwitchUserIcon
                            width={'22'}
                            height={'23'}
                            fill={theme.palette.error.main}
                          />
                        </Box>
                      </IconButton>
                      <Link
                        onClick={() => {
                          handleSwitchToPersonal('Customer')
                        }}
                      >
                        <Typography
                          variant='subtitle1'
                          color='error.main'
                          fontWeight='normal'
                          lineHeight='normal'
                          marginTop='0.5rem'
                        >
                          {translatedInput?.generalText?.switchToPersonalText}
                        </Typography>
                      </Link>
                    </Box>
                  )}
                  {pricingInformation?.is_business_admin && (
                    <Box display={'flex'} alignItems={'center'} justifyContent={'center'}>
                      <IconButton
                        sx={{ mr: '0.188rem' }}
                        onClick={() => {
                          handleDelete()
                        }}
                      >
                        <Box sx={{ height: '1.25rem', width: '1.25rem' }}>
                          <DeleteIcon fill={theme.palette.error.main} width='17' height='21' />
                        </Box>
                      </IconButton>
                      <Link
                        onClick={() => {
                          handleDelete()
                        }}
                      >
                        <Typography
                          variant='subtitle1'
                          color='error.main'
                          fontWeight='normal'
                          lineHeight='normal'
                          marginTop='0.5rem'
                        >
                          {translatedInput?.pricingPage?.deleteAccountLabel}
                        </Typography>
                      </Link>
                    </Box>
                  )}
                  <ActionAlertModal
                    showAlert={deleteAlertModal}
                    title={`${translatedInput?.pricingPage?.deleteAccountLabel}?`}
                    alertIcon={
                      <WarningIcon
                        fill={theme.palette.iconColors.contrastText}
                        width='46'
                        height='46'
                        stoke={theme.palette.common.white}
                      />
                    }
                  >
                    {isDeleteConfirmed ? deleteAlertModalContent2 : deleteAlertModalContent1}
                  </ActionAlertModal>
                </Box>
              )}
            </Box>
          </Box>
        </ClickAwayListener>
      </CustomScrollbarBox>
    </CustomModal>
  )
}
export default PricingPage
