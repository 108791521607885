import { Box, Button, Tab, Tabs, Typography, styled, useTheme } from '@mui/material'
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useAppSelector } from '../../../../_helpers/hooks'
import { TabPanelProps } from '../../../../components/common/tabPanel'
import { publicPagesState } from '../../../../slices/publicPagesSlice'
import PricingCards from './pricingCard'

const StyledTab = styled(Tabs)(({ theme }) => ({
  '& .MuiTab-root': {
    color: theme.palette.tab.light,
    fontWeight: 600,
    fontSize: '1rem',
  },
  '& .Mui-selected': {
    backgroundColor: `${theme.palette.common.white} !important`,
    color: `${theme.palette.publicPages.colorOne} !important`,
    fontWeight: 600,
    fontSize: '1rem',
    borderRadius: '0.5rem !important',
    boxShadow: '0rem 0.139rem 0.363rem 0rem #00000011',
  },
}))
function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography component='div'>{children}</Typography>
        </Box>
      )}
    </div>
  )
}
function a11yProps(index: number) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  }
}
const PricingSection = () => {
  const theme = useTheme()
  const [value, setValue] = useState(0)
  const navigate = useNavigate()
  const translatedInput = useAppSelector((state) => state?.translation.translatedData)
  const publicPagesData = useAppSelector(publicPagesState)
  const { pricingData } = publicPagesData
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue)
  }
  return (
    <Box
      mt={{ xs: 5 }}
      ml={{ xs: 5, sm: 2 }}
      mr={{ xs: 5, sm: 2 }}
      sx={{
        [theme.breakpoints.up(4000)]: {
          mt: 2,
        },
      }}
    >
      <Typography
        variant='h2'
        sx={{ mb: 1, color: 'publicPages.colorFour' }}
        fontSize={{ xs: '2rem', md: '2.5rem' }}
        lineHeight={{ xs: '2.5rem', md: '3rem' }}
        fontWeight={600}
      >
        {translatedInput?.landingScreen?.pricingHeader1}
        <span style={{ color: theme.palette.publicPages.light }}> for Everyone</span>
      </Typography>
      <Typography
        variant='h5'
        sx={{ color: 'publicPages.colorTwo' }}
        fontSize={{ xs: '.8rem', md: '1rem' }}
        lineHeight={{ xs: '1.563rem', md: '1.563rem' }}
        fontWeight={400}
        mt={2}
      >
        {translatedInput?.pricing?.pricingSubHeading}
      </Typography>
      <Box display={'flex'} justifyContent={'center'} mt={6}>
        <StyledTab
          value={value}
          onChange={handleChange}
          aria-label='full width tabs example'
          sx={{
            backgroundColor: 'label.main',
            padding: '0.313rem',
            borderRadius: '0.75rem',
            fontWeight: 600,
            maxWidth: '18.938rem',
          }}
        >
          <Tab label={'Monthly'} {...a11yProps(0)} />
          <Tab label={'Yearly'} {...a11yProps(1)} />
        </StyledTab>
      </Box>
      <TabPanel value={value} index={0}>
        <Box
          mt={6}
          display={{ xs: 'block', sm: 'flex' }}
          justifyContent={'center'}
          columnGap={10}
          maxWidth={{ lg: '100rem' }}
          marginLeft={'auto'}
          marginRight={'auto'}
        >
          <PricingCards pricingData={pricingData.monthly_plans} />
        </Box>
      </TabPanel>
      <TabPanel value={value} index={1}>
        <Box mt={6} display={{ xs: 'block', sm: 'flex' }} justifyContent={'center'} columnGap={10}>
          <PricingCards pricingData={pricingData.yearly_plans} />
        </Box>
      </TabPanel>

      <Box mt={8}>
        <Button size='large' variant='outlined' onClick={() => navigate('coming-soon')}>
          {translatedInput?.landingScreen?.comparePlans}
        </Button>
      </Box>
    </Box>
  )
}

export default PricingSection
