import { IIcon } from './iconConstants'
const UserWithLinesIcon = ({ width, height }: IIcon) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 47 41'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M44.67 0C45.956 0 47 1.014 47 2.263v36.474C47 39.987 45.93 41 44.67 41H2.33C1.045 41 0 39.987 0 38.737V2.263C0 1.013 1.07 0 2.33 0h42.34zm-.249 2.5H2.58v36H44.42v-36z'
        fill='gray'
      />
      <path
        d='M39.467 32.277v-2.54H8.031v2.54h31.436zM39.467 23.652v-2.716H24.702v2.716h14.765zM39.467 14.016v-2.77H24.702v2.77h14.765zM20.407 24.553a6.08 6.08 0 0 0-1.883-4.38 6.556 6.556 0 0 0-4.547-1.814c-1.705 0-3.34.653-4.547 1.814a6.081 6.081 0 0 0-1.883 4.38h2.694c0-.954.393-1.87 1.094-2.545a3.81 3.81 0 0 1 2.642-1.054c.99 0 1.941.38 2.642 1.054.7.675 1.094 1.59 1.094 2.545h2.694z'
        fill='gray'
      />
      <path
        d='M17.375 13.148c0 1.76-1.495 3.262-3.43 3.262-1.936 0-3.431-1.501-3.431-3.262 0-1.761 1.495-3.262 3.43-3.262 1.936 0 3.43 1.5 3.43 3.262z'
        stroke='gray'
        strokeWidth='2.325'
      />
    </svg>
  )
}
export default UserWithLinesIcon
