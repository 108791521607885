import { IContactUs } from '../_models/commonInterface'
import { get, post } from './apiServices'

const contactUs = async (data: IContactUs) => {
  const response = post('/contact-us', data)
  return response
}
const getDates = async () => {
  const response = await get('/terms-and-conditions/dates')
  return response
}

const getPricingPlans = async () => {
  const response = await get('/pricing-plans')
  return response
}
const publicPagesService = {
  contactUs,
  getPricingPlans,
  getDates,
}

export default publicPagesService
