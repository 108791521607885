import { Box, Button, Typography, useTheme } from '@mui/material'

import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { getUserTypeFromToken, isAuthenticated } from '../../../_helpers/cookie'
import { useAppDispatch, useAppSelector } from '../../../_helpers/hooks'
import { PlaceholderImageContainer } from '../../../components/common'
import { UnderConstructionIcon } from '../../../components/icons'
import { userDetails } from '../../../slices/userSlice'

const ComingSoonPage = () => {
  const dispatch = useAppDispatch()
  const theme = useTheme()
  const navigate = useNavigate()
  const translatedInput = useAppSelector((state) => state?.translation.translatedData)
  const loggedInUserType = getUserTypeFromToken()

  useEffect(() => {
    if (isAuthenticated()) {
      dispatch(userDetails())
    }
  }, [dispatch])
  const handleClick = () => {
    if (loggedInUserType === 'business') {
      navigate('/admin/dashboard')
    } else if (loggedInUserType === 'customer') {
      navigate('/customer/business')
    } else {
      navigate('/')
    }
  }
  return (
    <Box minHeight={'100vh'}>
      <PlaceholderImageContainer
        color={'common.black'}
        text={translatedInput?.landingScreen?.commingSoonText3}
        bordercolor={'transparent'}
        iconComponent={<UnderConstructionIcon fill={'none'} width='100%' height='auto' />}
      >
        <Box maxWidth={500}>
          <Typography textAlign={'center'} variant={'subtitle2'} color='secondary.main' mb={2}>
            {translatedInput?.landingScreen?.commingSoonText1}{' '}
            <span style={{ color: theme.palette.primary.main }}>
              {translatedInput?.landingScreen?.supportSlashqEmail}
            </span>
            . {translatedInput?.landingScreen?.commingSoonText2}
          </Typography>
        </Box>

        <Button variant='contained' onClick={handleClick}>
          {translatedInput?.landingScreen?.backtoHomeComingSoonText}
        </Button>
      </PlaceholderImageContainer>
    </Box>
  )
}

export default ComingSoonPage
