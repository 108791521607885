import { CloseOutlined, ExpandLess, ExpandMore } from '@mui/icons-material'

import {
  Box,
  Button,
  Divider,
  Drawer,
  Icon,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Paper,
  Stack,
  Typography,
  useTheme,
} from '@mui/material'
import { FC, useState } from 'react'

import { useNavigate } from 'react-router-dom'
import { useAppSelector } from '../../../_helpers/hooks'
import { isBusinessURL } from '../../../_helpers/routes'
import { ITab } from '../../../_models/tabsInterface'
import { SuitcaseIcon, UserIcon } from '../../../components/icons'
import { headerTabs } from '../items'

interface IMainDrawerProps {
  windowParam?: () => Window
  handleDrawerToggle: () => void
  mobileOpen: boolean
  handleNavigate: (redirectionPath: string, state?: Record<string, unknown>) => void
}
interface IUserType {
  title: string
  subtitle: string
  handleSelectUserType?: () => void
  logo: JSX.Element
}

const MainDrawer = (props: IMainDrawerProps) => {
  const translatedInput = useAppSelector((state) => state?.translation.translatedData)

  const { windowParam, handleDrawerToggle, handleNavigate, mobileOpen } = props
  const theme = useTheme()

  const container = windowParam !== undefined ? () => windowParam().document.body : undefined
  const [open, isOpen] = useState(false)
  const navigate = useNavigate()
  const handleClick = () => {
    isOpen((prev) => !prev)
  }
  const drawerWidth = '100vw'

  const UserType: FC<IUserType> = ({ logo, handleSelectUserType, title, subtitle }) => (
    <Stack
      direction='column'
      justifyContent='center'
      alignItems='center'
      spacing={1}
      onClick={handleSelectUserType}
    >
      <Icon>
        <Box
          sx={{
            height: 20,
          }}
        >
          {logo}
        </Box>
      </Icon>
      <Typography variant='h5'>{title}</Typography>
      <Typography sx={{ textAlign: 'center', pb: 1 }} color='secondary.dark' variant='subtitle1'>
        {subtitle}
      </Typography>
    </Stack>
  )
  const handleClickOnListItem = (item: ITab) => {
    handleDrawerToggle()
    if (isBusinessURL()) {
      window.location.replace(
        `${window.location.protocol}//${process.env.REACT_APP_BUSINESS_URL}${item.path}`,
      )
    } else navigate(item.path)
  }
  // Drawer - mobile view
  const drawer = (
    <>
      <Box onClick={handleDrawerToggle} sx={{ textAlign: 'end' }}>
        <Box>
          <IconButton
            color='primary'
            aria-label='open drawer'
            edge='end'
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { lg: 'none' } }}
          >
            <CloseOutlined />
          </IconButton>
        </Box>

        <List>
          {headerTabs.map((item: ITab) => (
            <ListItem key={item.id}>
              <ListItemButton
                sx={{ textAlign: 'center' }}
                onClick={() => handleClickOnListItem(item)}
              >
                <ListItemText primary={item.label} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </Box>
      <Stack sx={{ mx: 4 }}>
        <Button variant='outlined' size='large' onClick={() => handleNavigate('/login')}>
          {translatedInput?.loginPage?.signInBtn}
        </Button>
        <Button
          variant='contained'
          sx={{ mt: 2, pl: 4 }}
          size='large'
          onClick={handleClick}
          endIcon={open ? <ExpandMore /> : <ExpandLess />}
        >
          {translatedInput?.loginPage?.startFreeButton}
        </Button>
        {open && (
          <Paper
            sx={{
              boxShadow: theme.customShadows.z2,
              mt: 2,
            }}
          >
            <UserType
              title={translatedInput?.landingPage?.personalTitle}
              subtitle={translatedInput?.landingPage?.signUpCustomerSubTitle}
              logo={
                <UserIcon
                  fill={theme.palette.toggleButtonGroup.contrastText}
                  width='24'
                  height='24'
                />
              }
              handleSelectUserType={() =>
                handleNavigate('/sign-up', { state: { userType: 'CUSTOMER' } })
              }
            />
            <Divider sx={{ my: 1 }} />
            <UserType
              title={translatedInput?.landingScreen?.businessOwnerBtn}
              subtitle={translatedInput?.landingPage?.signUpBusinessSubTitle}
              logo={
                <SuitcaseIcon
                  fill={theme.palette.toggleButtonGroup.contrastText}
                  width='24'
                  height='24'
                />
              }
              handleSelectUserType={() =>
                handleNavigate('/sign-up', { state: { userType: 'BUSINESS' } })
              }
            />
          </Paper>
        )}
      </Stack>
    </>
  )
  return (
    <>
      <Drawer
        container={container}
        variant='temporary'
        open={mobileOpen}
        onClose={handleDrawerToggle}
        ModalProps={{
          keepMounted: true,
        }}
        sx={{
          display: { xs: 'block', lg: 'none' },
          '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
        }}
      >
        {drawer}
      </Drawer>
    </>
  )
}

export default MainDrawer
