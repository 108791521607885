import Box from '@mui/material/Box'
import * as React from 'react'
import { Outlet, useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import { isAuthenticated } from '../../_helpers/cookie'
import { useAppDispatch, useAppSelector } from '../../_helpers/hooks'
import { getDomainNames, isBusinessURL } from '../../_helpers/routes'

import { businessVoyagerConfiguration } from '../../slices/voyagerSlice'
import MainDrawer from './Drawer'

import { useEffect, useState } from 'react'
import { translation, translationData } from '../../slices/translationSlice'
import PublicFooter from './Footer'
import PublicHeader from './Header'

export default function PublicLayout() {
  const [mobileOpen, setMobileOpen] = React.useState(false)
  const navigate = useNavigate()
  const [urlParams] = useSearchParams()
  const isRescheduleLink = urlParams.get('reschedule') ? true : false
  const isConfirmRescheduleLink = urlParams.get('confirm-reschedule') ? true : false
  const bookingToken = urlParams.get('token')
  const linkExpiryTime = urlParams.get('expires')
  const translationState = useAppSelector(translationData)
  const { selectedLanguage } = translationState
  const dispatch = useAppDispatch()
  const location = useLocation()
  const [key, setKey] = useState(0)
  const [prevPath, setPrevPath] = useState('')

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })

    // Check if the current path is the same as the previous path
    if (location.pathname === '/' && prevPath === '/') {
      // Force re-render by updating the key
      setKey((prevKey) => prevKey + 1)
    }
    // Update the previous path
    setPrevPath(location.pathname)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location])
  React.useEffect(() => {
    dispatch(translation(selectedLanguage))
    // handle business url flow
    if (isBusinessURL()) {
      const domainNames = getDomainNames()
      navigate(`/business/${domainNames[0]}${window.location.search}`)
    }
    // handle guest new booking
    if (!isAuthenticated() && isBusinessURL()) {
      dispatch(businessVoyagerConfiguration())
    }
  }, [
    bookingToken,
    selectedLanguage,
    dispatch,
    isConfirmRescheduleLink,
    isRescheduleLink,
    linkExpiryTime,
    navigate,
  ])

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen)
  }
  const handleNavigateToSignUp = (
    redirectionPath: string,
    routeState?: Record<string, unknown>,
  ) => {
    navigate(redirectionPath, routeState)
  }

  return (
    <Box key={key}>
      <PublicHeader handleNavigate={handleNavigateToSignUp} handleDrawerToggle={handleDrawerToggle}>
        <Box component='nav'>
          <MainDrawer
            mobileOpen={mobileOpen}
            handleNavigate={handleNavigateToSignUp}
            handleDrawerToggle={handleDrawerToggle}
          />
        </Box>
      </PublicHeader>
      <Box sx={{ minHeight: '80vh' }}>
        <Outlet />
      </Box>

      <PublicFooter />
    </Box>
  )
}
