import { Box, Grid, ToggleButton, Typography, useTheme } from '@mui/material'
import React from 'react'
import { useAppSelector } from '../../../_helpers/hooks'
import { ISlotInfo, ISlots } from '../../../_models/newBookingInterface'
import { StyledToggleButtonGroup } from '../../../components/@extended/customToggleGroup'
import { MoonIcon, SunIcon, SunRiseIcon, SunSetIcon } from '../../../components/icons'
import { newBookingData } from '../../../slices/newBookingSlice'

interface ISlotsNewBooking {
  isDrawerExpanded?: boolean
  setSlotAvailability: React.Dispatch<React.SetStateAction<number>>
  onSlotSelection: (slotId: number) => void
  slotInfo: ISlotInfo
  setSlotInfo: React.Dispatch<React.SetStateAction<ISlotInfo>>
}

export const Slots = (props: ISlotsNewBooking) => {
  const { slotInfo, setSlotInfo, setSlotAvailability, isDrawerExpanded, onSlotSelection } = props
  const theme = useTheme()
  const newBookingState = useAppSelector(newBookingData)
  const translatedInput = useAppSelector((state) => state?.translation.translatedData)
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const { slotInformation }: any = newBookingState

  const handleSlotSelect = (slot: ISlots) => {
    setSlotInfo((prevState) => ({
      ...prevState,
      slotId: slot.id,
    }))
    setSlotAvailability(slot?.number_of_available_slots)
    onSlotSelection(slot?.id)
  }

  const getSlotText = (slot: ISlots): React.ReactNode => {
    if (slot.percentage_filled === 100) {
      if (slot.number_of_available_slots === 0) return translatedInput?.newBooking?.fullyBooked
      else if (slot.number_of_available_slots === 1)
        return `${slot.number_of_available_slots} ${translatedInput?.newBooking?.seatLeft}`
      else return `${slot.number_of_available_slots} ${translatedInput?.newBooking?.seatsLeft}`
    } else {
      if (slot.percentage_filled > 70) {
        if (slot.number_of_available_slots === 1)
          return `${slot.number_of_available_slots} ${translatedInput?.newBooking?.seatLeft}`
        else return `${slot.number_of_available_slots} ${translatedInput?.newBooking?.seatsLeft}`
      } else {
        if (slot.number_of_available_slots === 1)
          return `${slot.number_of_available_slots} ${translatedInput?.newBooking?.seatAvailable}`
        else
          return `${slot.number_of_available_slots} ${translatedInput?.newBooking?.seatsAvailable}`
      }
    }
  }

  const renderTimeSlot = (slot: ISlots, timeOfDay: string) => (
    <Grid item xs={6} sm={3} md={3} lg={3} xl={3} mt={1} key={slot.id}>
      <StyledToggleButtonGroup
        value={slotInfo?.slotId}
        exclusive
        aria-label='status'
        sx={{
          display: 'flex',
          cursor: slot.percentage_filled === 100 ? 'not-allowed' : 'default',
        }}
      >
        <ToggleButton
          value={slot.id}
          aria-label='Upcoming'
          key={slot.id}
          disabled={slot.percentage_filled === 100}
          sx={{
            width: '100%',
            minWidth: 'auto',
            padding: '0.438rem 1.125rem !important',
            minHeight: 'auto',
          }}
          onClick={() => {
            handleSlotSelect(slot)
            setSlotInfo((prevState) => ({
              ...prevState,
              slotTime: `${slot.slot_from} - ${slot.slot_to}`,
              slotInterval: timeOfDay,
            }))
          }}
        >
          {slot.slot_from} - {slot.slot_to}
          <Typography
            fontSize={12}
            color={slot.percentage_filled > 70 ? 'error' : 'toggleButtonGroup.contrastText'}
          >
            {getSlotText(slot)}
          </Typography>
        </ToggleButton>
      </StyledToggleButtonGroup>
    </Grid>
  )

  return (
    <Box mt={2} width={isDrawerExpanded ? { xs: '100%', md: '50%' } : '100%'}>
      {(slotInformation?.slots_morning?.length ||
        slotInformation?.slots_afternoon?.length ||
        slotInformation?.slots_evening?.length ||
        slotInformation?.slots_night?.length) > 0 && (
        <Box>
          <Typography variant='subtitle1' color='table.light'>
            {translatedInput?.newBooking?.pickSlotTitle}
          </Typography>
          {slotInformation?.slots_night?.length > 0 && (
            <Box>
              <Box sx={{ display: 'flex' }}>
                <Box mr={1} mt={1}>
                  <MoonIcon
                    fill={theme.palette.toggleButtonGroup.contrastText}
                    width='24'
                    height='24'
                  />
                </Box>
                <Typography variant='subtitle2' color='toggleButtonGroup.contrastText' mt={1}>
                  {translatedInput?.newBooking?.nightTitle}
                </Typography>
              </Box>
              <Grid container columnSpacing={'0.5rem'}>
                {slotInformation?.slots_night?.map((slot: ISlots) => renderTimeSlot(slot, 'night'))}
              </Grid>
            </Box>
          )}
          {slotInformation?.slots_morning?.length > 0 && (
            <Box>
              <Box sx={{ display: 'flex' }}>
                <Box mr={1} mt={1}>
                  <SunRiseIcon
                    fill='none'
                    stoke={theme.palette.toggleButtonGroup.contrastText}
                    width='24'
                    height='24'
                  />
                </Box>
                <Typography variant='subtitle2' color='toggleButtonGroup.contrastText' mt={1}>
                  {translatedInput?.newBooking?.morningTitle}
                </Typography>
              </Box>
              <Grid container columnSpacing={'0.5rem'}>
                {slotInformation?.slots_morning?.map((slot: ISlots) =>
                  renderTimeSlot(slot, 'morning'),
                )}
              </Grid>
            </Box>
          )}
          {slotInformation?.slots_afternoon?.length > 0 && (
            <Box>
              <Box sx={{ display: 'flex' }}>
                <Box mr={1} mt={1}>
                  <SunIcon
                    fill='none'
                    stoke={theme.palette.toggleButtonGroup.contrastText}
                    width='24'
                    height='24'
                  />
                </Box>
                <Typography variant='subtitle2' color='toggleButtonGroup.contrastText' mt={1}>
                  {translatedInput?.newBooking?.afterNoonTitle}
                </Typography>
              </Box>
              <Grid container columnSpacing={'0.5rem'}>
                {slotInformation?.slots_afternoon?.map((slot: ISlots) =>
                  renderTimeSlot(slot, 'afternoon'),
                )}
              </Grid>
            </Box>
          )}
          {slotInformation?.slots_evening?.length > 0 && (
            <Box>
              <Box sx={{ display: 'flex' }}>
                <Box mr={1} mt={1}>
                  <SunSetIcon
                    fill={'none'}
                    stoke={theme.palette.toggleButtonGroup.contrastText}
                    width='24'
                    height='24'
                  />
                </Box>
                <Typography variant='subtitle2' color='toggleButtonGroup.contrastText' mt={1}>
                  {translatedInput?.newBooking?.eveningTitle}
                </Typography>
              </Box>
              <Grid container columnSpacing={'0.5rem'}>
                {slotInformation?.slots_evening?.map((slot: ISlots) =>
                  renderTimeSlot(slot, 'evening'),
                )}
              </Grid>
            </Box>
          )}
        </Box>
      )}
    </Box>
  )
}

export default Slots
