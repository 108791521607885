import Brightness1Icon from '@mui/icons-material/Brightness1'
import { Box, Container, List, ListItem, Typography, useTheme } from '@mui/material'
import { useAppSelector } from '../../../../_helpers/hooks'
import { publicPagesState } from '../../../../slices/publicPagesSlice'

const PrivacyPolicy = () => {
  const theme = useTheme()
  const translatedInput = useAppSelector((state) => state?.translation.translatedData)
  const publicPagesData = useAppSelector(publicPagesState)
  const { datesData } = publicPagesData
  const textStyle = {
    fontSize: { xs: '0.6rem', sm: '1rem', lg: '1rem' },
    lineHeight: { xs: '0.9rem', sm: '1.563rem', lg: '1.563rem' },
    fontWeight: 300,
    span: {
      color: theme.palette.common.black,
      fontWeight: 500,
    },
  }
  const headingStyle = {
    fontSize: { sm: '1.125rem', lg: '1.75rem' },
    lineHeight: '1.875rem',
    fontWeight: 500,
    letterSpacing: 'normal',
    marginTop: { xs: '1rem', sm: '1.125rem', lg: '2.5rem' },
    marginBottom: { sm: '0.688rem', lg: '1.75rem' },
  }
  const subHeadingStyle = {
    fontSize: { xs: '0.8rem', sm: '1rem', lg: '1rem' },
    marginTop: '0.938rem',
  }

  const bulletItemStyle = {
    fontSize: '0.625rem',
    paddingRight: '0.625rem',
    paddingTop: '0.450rem',
    color: theme.palette.primary.main,
  }

  const listItemStyle = {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    paddingLeft: '0.5rem',
    paddingBottom: '0',
    paddingTop: '0',
  }
  const flexStyle = {
    display: 'flex',
    flexDirection: 'column',
  }

  const informationList = [
    {
      label: translatedInput?.privacyPolicyPage?.informationListLabel1,
      value: 0,
    },
    {
      label: translatedInput?.privacyPolicyPage?.informationListLabel2,
      value: 1,
    },
    {
      label: translatedInput?.privacyPolicyPage?.informationListLabel3,
      value: 2,
    },
    {
      label: translatedInput?.privacyPolicyPage?.informationListLabel4,
      value: 3,
    },
    { label: translatedInput?.privacyPolicyPage?.informationListLabel5, value: 4 },
    { label: translatedInput?.privacyPolicyPage?.informationListLabel6, value: 5 },
  ]

  const contentList = [
    {
      Heading: translatedInput?.privacyPolicyPage?.contentListHeader1,
      text: translatedInput?.privacyPolicyPage?.contentListText1,
      value: 0,
    },
    {
      Heading: translatedInput?.privacyPolicyPage?.contentListHeader2,
      text: translatedInput?.privacyPolicyPage?.contentListText2,
      value: 1,
    },
    {
      Heading: translatedInput?.privacyPolicyPage?.contentListHeader3,
      text: translatedInput?.privacyPolicyPage?.contentListText3,
      value: 2,
    },
    {
      Heading: translatedInput?.privacyPolicyPage?.contentListHeader4,
      text: translatedInput?.privacyPolicyPage?.contentListText4,
      value: 3,
    },
    {
      Heading: translatedInput?.privacyPolicyPage?.contentListHeader5,
      text: translatedInput?.privacyPolicyPage?.contentListText5,
      value: 4,
    },
    {
      Heading: translatedInput?.privacyPolicyPage?.contentListHeader6,
      text: translatedInput?.privacyPolicyPage?.contentListText6,
      value: 5,
    },
    {
      Heading: translatedInput?.privacyPolicyPage?.contentListHeader7,
      text: translatedInput?.privacyPolicyPage?.contentListText7,
      value: 6,
    },
    {
      Heading: translatedInput?.privacyPolicyPage?.contentListHeader8,
      text: translatedInput?.privacyPolicyPage?.contentListText8,
      value: 7,
    },
    {
      Heading: translatedInput?.privacyPolicyPage?.contentListHeader9,
      text: translatedInput?.privacyPolicyPage?.contentListText9,
      value: 8,
    },
  ]
  return (
    <Container
      sx={{
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        padding: { sm: '0 1rem 5.563rem', lg: '0 0 5.563rem' },
        maxWidth: '100rem',
        margin: '0 auto',
        fontFamily: 'Lexend',
      }}
    >
      <Box
        sx={{
          width: '100%',
          height: 'auto',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-start',
          alignItems: 'flex-start',
          margin: '2.188rem 0 0.938rem',
        }}
      >
        <Typography
          sx={{
            fontWeight: 600,
            fontSize: { xs: '1.5rem', sm: '1.5rem', lg: '2.25rem' },
            lineHeight: { xs: '1.5rem', sm: '1.5rem', lg: '3.375rem' },
          }}
        >
          {translatedInput?.privacyPolicyPage?.slashQPrivacyNoteHeader}
        </Typography>
        <Typography
          sx={{
            fontSize: { xs: '0.75rem', lg: '0.875rem' },
            lineHeight: 1.438,
            marginTop: '0.313rem',
            color: theme.palette.secondary.main,
            '& .highlighted-text': {
              color: theme.palette.common.black,
            },
          }}
        >
          {translatedInput?.termsOfService?.lastUpdatedText}{' '}
          <span className='highlighted-text'>{datesData?.privacy_policy_last_updated}</span>
        </Typography>
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-start',
          alignItems: 'flex-start',
        }}
      >
        <Typography sx={textStyle}>
          {translatedInput?.privacyPolicyPage?.privacyPolicyDesc1}
        </Typography>
      </Box>

      <Box sx={flexStyle}>
        <Box sx={flexStyle}>
          <Typography sx={headingStyle}>
            {translatedInput?.privacyPolicyPage?.privacyPolicyDesc3}
          </Typography>
          <Box sx={flexStyle}>
            <Typography sx={textStyle}>
              {translatedInput?.privacyPolicyPage?.privacyPolicyDesc2}
            </Typography>
            <Typography sx={subHeadingStyle}>
              {translatedInput?.privacyPolicyPage?.privacyPolicyDesc4}
            </Typography>
            <List sx={{ marginBottom: '0.938rem' }}>
              {informationList.map((item) => (
                <ListItem sx={listItemStyle} key={item.value}>
                  <span style={bulletItemStyle}>
                    <Brightness1Icon fontSize='inherit' />
                  </span>
                  <Typography
                    sx={{
                      ...textStyle,
                      paddingTop: { xs: '0.4rem', sm: '0', lg: '0' },
                    }}
                  >
                    {item.label}
                  </Typography>
                </ListItem>
              ))}
            </List>
            <Typography sx={textStyle}>
              {translatedInput?.privacyPolicyPage?.privacyPolicyDesc5}
            </Typography>
          </Box>
        </Box>
        {contentList.map((item) => (
          <Box sx={flexStyle} key={item.value}>
            <Typography sx={headingStyle}>{item.Heading}</Typography>
            <Typography sx={textStyle}>{item.text}</Typography>
          </Box>
        ))}
      </Box>
    </Container>
  )
}

export default PrivacyPolicy
