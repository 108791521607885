/* eslint-disable react-hooks/exhaustive-deps */
import { Autocomplete, Box, Button, Grid, TextField, Typography, useTheme } from '@mui/material'
import Pusher from 'pusher-js'
import React, { useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from '../../_helpers/hooks'
import { IScannerDetails, IScannerFacility } from '../../_models/pusherInterface'
import CustomModal from '../../components/@extended/modal'
import { UserWithLinesIcon } from '../../components/icons'
import useQueue from '../../hooks/useQueue'
import { dashboardState, getBookingList } from '../../slices/dashboardSlice'
import { scannerData } from '../../slices/scannerSlice'
import ScannerDetailsPopup from './scannerDetails'
interface IScannerModelProps {
  children?: React.ReactNode
  isOpen: boolean
  isClosed: (data: boolean) => void
}

const ScannerPopup = (props: IScannerModelProps) => {
  const translatedInput = useAppSelector((state) => state?.translation.translatedData)
  const { dashboardBookingListData } = useAppSelector(dashboardState)
  const { filters } = dashboardBookingListData
  const [open, setOpen] = useState(false)
  const pusherData = useAppSelector(scannerData)
  const { scannerFacility } = pusherData
  const [selectBoxValue, setSelectBoxValue] = useState('all')
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [queue, enqueue, dequeue, front, rear, isEmpty, size, clear] = useQueue<IScannerDetails>([])
  const [selectedFacility, setSelectedFacility] = useState<IScannerFacility>()
  const allFacilitiesElement = [{ id: 'all', name: 'All Facilities' }]
  const newFacilitiesArray: IScannerFacility[] = [
    ...(scannerFacility?.facilities?.length > 1 ? allFacilitiesElement : []),
    ...(scannerFacility?.facilities as IScannerFacility[]),
  ]

  const handleClose = () => {
    setOpen(false)
    props.isClosed(false)
    dispatch(getBookingList(filters))
    setSelectedFacility({ id: 'all', name: 'All Facilities' })
  }
  const handleChange = (value: IScannerFacility | null) => {
    if (value) {
      setSelectBoxValue(value?.id)
      setSelectedFacility(value)
    } else {
      setSelectBoxValue('')
      setSelectedFacility(undefined)
    }
  }
  const dispatch = useAppDispatch()
  useEffect(() => {
    setOpen(props.isOpen)
  }, [props])

  useEffect(() => {
    const handleBackButton = () => {
      handleClose()
    }
    window.addEventListener('popstate', handleBackButton)
    return () => {
      window.removeEventListener('popstate', handleBackButton)
    }
  }, [])
  const [dataFromPusher, setDataFromPusher] = useState<IScannerDetails>()
  const [allDataFromPusher, setAllDataFromPusher] = useState<IScannerDetails[]>([])

  useEffect(() => {
    if (pusherData?.pusherAppKey !== '') {
      const pusherInstance = new Pusher(pusherData?.pusherAppKey, {
        cluster: pusherData?.pusherAppCluster,
        wsHost: pusherData?.pusherWsHost,
        wssPort: 6001,
      })

      // Subscribe to multiple channels
      const channels = pusherData?.pusherChannel?.map((channelName) => {
        const channel = pusherInstance?.subscribe(channelName)
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        channel?.bind(pusherData?.pusherEventName, (data: any) => {
          const parsedData = JSON.parse(data.payload)
          setDataFromPusher(parsedData)
        })
        return channel
      })

      return () => {
        channels?.forEach((channel) => {
          pusherInstance.unsubscribe(channel?.name)
        })
      }
    }
  }, [pusherData])

  useEffect(() => {
    if (dataFromPusher?.token?.book_id) {
      const filterData = allDataFromPusher.filter(
        (item: IScannerDetails) => item.token.book_id === dataFromPusher?.token?.book_id,
      )
      if (filterData.length === 0) {
        setAllDataFromPusher([...allDataFromPusher, dataFromPusher])
      }
    }
  }, [dataFromPusher])

  useEffect(() => {
    clear()
    allDataFromPusher.length > 0 &&
      allDataFromPusher.forEach((item) => {
        if (selectBoxValue === 'all') {
          enqueue(item)
        } else if (item.facility.id === String(selectBoxValue)) {
          enqueue(item)
        }
      })
  }, [allDataFromPusher, selectBoxValue])

  const removeDataFromPusher = () => {
    const currentData = front()
    const tempPusherData = [...allDataFromPusher]
    tempPusherData.splice(
      allDataFromPusher.findIndex((item) => item.token.book_id === currentData?.token.book_id),
      1,
    )
    dequeue()
    setAllDataFromPusher(tempPusherData)
  }
  const theme = useTheme()

  return (
    <CustomModal isOpen={open} onClose={handleClose}>
      <Box sx={{ minWidth: { xs: '0rem', sm: '30.063rem' } }}>
        <Box display={{ xs: 'block', sm: 'flex' }}>
          <Box justifyContent={'start'} flex={4}>
            <Autocomplete
              fullWidth
              id='combo-box-demo'
              onChange={(event, value) => {
                handleChange(value)
              }}
              value={selectedFacility}
              defaultValue={
                newFacilitiesArray.length > 1
                  ? { id: 'all', name: 'All Facilities' }
                  : newFacilitiesArray[0]
                  ? newFacilitiesArray[0]
                  : null
              }
              isOptionEqualToValue={(option, value) => option?.id === value?.id}
              getOptionLabel={(option) => option?.name}
              options={newFacilitiesArray}
              clearOnBlur
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={translatedInput?.common?.selectFacilityLabel}
                  InputProps={{
                    ...params.InputProps,
                    style: { color: theme?.palette?.secondary?.main, fontSize: 14 },
                  }}
                />
              )}
              renderOption={(props, option) => (
                <li {...props} key={option?.id}>
                  {option?.name}
                </li>
              )}
            />
          </Box>
          <Box
            justifyContent={{ xs: 'start', sm: 'end' }}
            flex={6}
            display={'flex'}
            mt={{ xs: 2, sm: '0rem' }}
          >
            <Typography fontSize='0.875rem' color='secondary.dark' variant='subtitle1' pt={1}>
              {`${translatedInput.scannerDetails.inQueueText}:`}
            </Typography>
            <Typography fontSize='0.875rem' color='common.black' variant='subtitle1' pl={1} pt={1}>
              {size()}
            </Typography>
          </Box>
        </Box>
        {!isEmpty() ? (
          <ScannerDetailsPopup
            scannedTokenDetails={front()}
            removeDataFromPusher={removeDataFromPusher}
          />
        ) : (
          <Box>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                paddingTop: '11.18rem',
                padddingBottom: '',
              }}
            >
              <UserWithLinesIcon height='41' width='47' />
              <Typography
                variant='subtitle1'
                fontWeight='400'
                color='secondary.main'
                align='center'
                paddingTop='1.375rem'
                paddingBottom='6.75rem'
                textAlign='center'
                maxWidth='18rem'
              >
                {translatedInput?.scannerDetails?.scannedDetailsDisplayedText}
              </Typography>
            </Box>

            <Box mt={4}>
              <Grid container columnSpacing={'1.25rem'}>
                <Grid item xs={12} sm={3} md={3} lg={3} xl={3} />

                <Grid item xs={12} sm={6} md={6} lg={6} xl={6} mt={4}>
                  <Button
                    sx={{ width: '100%' }}
                    variant='outlined'
                    size='large'
                    onClick={handleClose}
                  >
                    {translatedInput.common.cancelBtn}
                  </Button>
                </Grid>
                <Grid item xs={12} sm={3} md={3} lg={3} xl={3} />
              </Grid>
            </Box>
          </Box>
        )}
      </Box>
    </CustomModal>
  )
}
export default ScannerPopup
