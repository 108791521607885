/* eslint-disable camelcase */
import { ICancelParams } from '../_models/pricingInterface'
import { get, post } from './apiServices'

const getPricingDetails = async () => {
  const response = await get('get-payment-plans')
  return response
}

const getPricingHistory = async () => {
  const response = await get('business/subscription-history')
  return response
}

const getPurchaseInfo = async (planId: number, redirectUrl: string) => {
  const response = await post('payment/purchase-info', { plan: planId, redirectUrl: redirectUrl })
  return response
}

const getPaymentDetails = async (txnId: string) => {
  const response = await get(`/payment/details?txn_id=${txnId}`)
  return response
}
const getProPaymentDetails = async (planId: number) => {
  const response = await get(`business/payment-details?plan_id=${planId}`)
  return response
}

const getSubscriptionPlanDetails = async () => {
  const response = await get('business/subscription-plan')
  return response
}

const trialPlanDetails = async (planId: number) => {
  const response = await post('subscription/trial-plan', { plan_id: planId })
  return response
}

const userSubscriptionDetails = async (token: string) => {
  const response = await get(`/subscription-renewal-plans?expires=${token}`)
  return response
}

const getPricingLimits = async () => {
  const response = await get('plan-limits')
  return response
}
const cancelAutoRenewal = async (cancelParams: ICancelParams) => {
  const response = await post('payment/cancel-subscription', cancelParams)
  return response
}
const pricingService = {
  cancelAutoRenewal,
  getPricingDetails,
  getPricingHistory,
  getPurchaseInfo,
  getSubscriptionPlanDetails,
  getPaymentDetails,
  trialPlanDetails,
  userSubscriptionDetails,
  getPricingLimits,
  getProPaymentDetails,
}

export default pricingService
