import { InfoOutlined, VisibilityOffOutlined, VisibilityOutlined } from '@mui/icons-material'
import {
  Box,
  Button,
  Grid,
  IconButton,
  InputAdornment,
  Link,
  Paper,
  TextField,
  Typography,
} from '@mui/material'
import { isUndefined } from 'lodash'
import { useEffect, useState } from 'react'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import 'react-phone-input-2/lib/material.css'
import { useNavigate } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from '../../../_helpers/hooks'
import { ILoginFormData } from '../../../_models/authenticationInterface'
import CopyRight from '../../../features/copyRight/CopyRight'
import Logo from '../../../images/logo.svg'
import { authenticationState, signIn } from '../../../slices/authenticationSlice'
import { isBusinessURL } from '../../../_helpers/routes'

const LoginPage = () => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  const translatedInput = useAppSelector((state) => state?.translation.translatedData)
  const [showPassword, setShowPassword] = useState(false)
  const handleClickShowPassword = () => {
    setShowPassword((prevValue) => !prevValue)
  }
  const {
    handleSubmit,
    control,
    setError,
    formState: { isSubmitted },
  } = useForm<ILoginFormData>({
    mode: 'onChange',
  })

  const onSubmit: SubmitHandler<ILoginFormData> = (data) => {
    dispatch(signIn(data))
  }

  const { error } = useAppSelector(authenticationState)
  useEffect(() => {
    if (error.message) {
      setError('email', { type: 'email' })
      setError('password', { type: 'password' })
    }
  }, [error, setError])

  return (
    <Grid item xs={12} sm={6} md={5} component={Paper} elevation={0} square>
      <Grid
        container
        direction='column'
        alignItems='center'
        height='100%'
        justifyContent='center'
        sx={{
          mx: { xs: 'auto' },
          p: { xs: 4, sm: 4, md: 5, lg: 10 },
          maxWidth: '90%',
        }}
      >
        <Grid item sx={{ flexGrow: 1 }}>
          <Grid
            container
            direction='column'
            alignItems='center'
            height='100%'
            justifyContent='center'
          >
            <Grid item>
              <Box
                component='img'
                sx={{
                  height: 33,
                  marginBottom: { xs: 5, md: 8 },
                  cursor: 'pointer',
                }}
                alt='Logo'
                src={Logo}
                onClick={() =>
                  isBusinessURL()
                    ? window.location.replace(
                        `${window.location.protocol}//${process.env.REACT_APP_BUSINESS_URL}/`,
                      )
                    : navigate('/')
                }
              />
              <Typography variant='h2'>{translatedInput?.loginPage?.signInTitle}</Typography>
              <Box component={'span'} color='secondary.main'>
                <Typography variant='subtitle1' sx={{ my: 2 }}>
                  {translatedInput?.loginPage?.signInText}
                </Typography>
              </Box>

              <form noValidate onSubmit={handleSubmit(onSubmit)}>
                <Box sx={{ mt: 1 }}>
                  <Controller
                    name='email'
                    control={control}
                    defaultValue=''
                    rules={{
                      required: translatedInput?.common?.emailAddressRequired,
                      maxLength: {
                        value: 64,
                        message: translatedInput?.common?.emailMaxLengthError,
                      },
                      pattern: {
                        value: /^[a-zA-Z0-9._+]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)+$/,
                        message: translatedInput?.common?.emailValid,
                      },
                    }}
                    render={({ field, fieldState: { error } }) => (
                      <TextField
                        {...field}
                        margin='normal'
                        fullWidth
                        label={translatedInput?.common?.emailPlaceholder}
                        error={isSubmitted && !isUndefined(error)}
                        helperText={error?.message}
                      />
                    )}
                  />
                  <Controller
                    name='password'
                    control={control}
                    defaultValue=''
                    rules={{
                      required: translatedInput?.common?.passwordRequired,
                      // pattern: {
                      //   value: /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/,
                      //   message: 'Please enter a valid password',
                      // },
                      // minLength: {
                      //   value: 8,
                      //   message: 'Password must have at least 8 characters',
                      // },
                      // maxLength: {
                      //   value: 16,
                      //   message: 'Password must have less than 16 characters',
                      // },
                    }}
                    render={({ field, fieldState: { error } }) => (
                      <TextField
                        {...field}
                        margin='normal'
                        fullWidth
                        label={translatedInput?.common?.passwordLabel}
                        type={!showPassword ? 'password' : 'text'}
                        error={isSubmitted && !isUndefined(error)}
                        helperText={error?.message}
                        InputProps={{
                          endAdornment: (
                            <>
                              <InputAdornment position='end'>
                                <IconButton
                                  aria-label='toggle password visibility'
                                  onClick={handleClickShowPassword}
                                  edge='end'
                                  sx={{ color: 'secondary.light', mr: 0 }}
                                >
                                  {showPassword ? (
                                    <VisibilityOutlined />
                                  ) : (
                                    <VisibilityOffOutlined />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            </>
                          ),
                        }}
                      />
                    )}
                  />

                  {isSubmitted && error?.message && (
                    <Typography variant='body2' my={2} color='error.dark'>
                      <IconButton color='error' sx={{ paddingLeft: '0' }}>
                        <InfoOutlined />
                      </IconButton>
                      {error.message}
                    </Typography>
                  )}

                  <Box>
                    <Link
                      variant='body2'
                      underline='hover'
                      sx={{ pt: 1 }}
                      onClick={() => navigate('/forget-password')}
                      fontWeight='400'
                    >
                      {translatedInput?.common?.forgotPassword}
                    </Link>
                  </Box>

                  <Button
                    type='submit'
                    fullWidth
                    variant='contained'
                    sx={{ mt: 3, mb: 2 }}
                    size='large'
                  >
                    <Typography variant='h5'>{translatedInput?.loginPage?.signInBtn}</Typography>
                  </Button>

                  <Typography variant='body2' mt={2} sx={{ textAlign: 'center' }}>
                    {translatedInput?.loginPage?.dontHaveUserAccount}&nbsp;
                    <Link underline='hover' variant='body2' onClick={() => navigate('/sign-up')}>
                      {translatedInput?.loginPage?.signUpLink}
                    </Link>
                  </Typography>
                </Box>
              </form>
            </Grid>
          </Grid>
        </Grid>

        <Grid item>
          <CopyRight sx={{ mb: 2 }} />
        </Grid>
      </Grid>
    </Grid>
  )
}

export default LoginPage
