import { CssBaseline, StyledEngineProvider } from '@mui/material'
import {
  ExtendedTypographyOptions,
  PaletteColor,
  PaletteColorOptions,
  ThemeProvider,
  createTheme,
} from '@mui/material/styles'
import { TypographyOptions } from '@mui/material/styles/createTypography'
import { FC, ReactNode, useMemo } from 'react'

import componentsOverride from './overrides'
import { palette } from './palette'
import { customShadows } from './shadows'
import Typography from './typography'
interface themeCustomizationProps {
  children: ReactNode
}

declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    drawerTitle: true
    drawerSubtitle1: true
    drawerSubtitle2: true
    drawerSubtitle3: true
    drawerText: true
  }
}
declare module '@mui/material/styles' {
  interface Theme {
    customShadows: {
      z1: string
      z2: string
    }
  }
  interface IconBackgroundColors {
    light?: string
    main: string
    dark?: string
    contrastText?: string
    contrastTextHover?: string
    colorOne?: string
    colorOneHover?: string
    colorTwo?: string
    colorThree?: string
    colorFour?: string
    colorFive?: string
    colorSix?: string
    colorDivider?: string
    colorSeven?: string
    colorEight?: string
    colorNine?: string
    colorTen?: string
    colorEleven?: string
    colorTwelve?: string
  }
  interface PaletteOptions {
    label: PaletteColorOptions
    description: PaletteColorOptions
    tab: PaletteColorOptions
    textGrey: PaletteColorOptions
    backdrop: PaletteColorOptions
    border: PaletteColorOptions
    alertBackdrop: PaletteColorOptions
    shadow: PaletteColorOptions
    toggleButtonGroup: PaletteColorOptions
    pagination: PaletteColorOptions
    card: PaletteColorOptions
    status: PaletteColorOptions
    table: PaletteColorOptions
    chart: PaletteColorOptions
    toast: PaletteColorOptions
    scroll: PaletteColorOptions
    rgba: PaletteColorOptions
    iconColors: PaletteColorOptions
    icons: PaletteColorOptions
    selection: PaletteColorOptions
    floor: PaletteColorOptions
    csv: PaletteColorOptions
    seat: PaletteColorOptions
    profileIcon: IconBackgroundColors
    facilityList: IconBackgroundColors
    publicPages: IconBackgroundColors
    pricingAlert: IconBackgroundColors
  }
  interface Palette {
    label: PaletteColor
    description: PaletteColor
    tab: PaletteColor
    textGrey: PaletteColor
    backdrop: PaletteColor
    border: PaletteColor
    alertBackdrop: PaletteColor
    shadow: PaletteColor
    toggleButtonGroup: PaletteColor
    pagination: PaletteColor
    card: PaletteColor
    status: PaletteColor
    table: PaletteColor
    chart: PaletteColor
    toast: PaletteColor
    scroll: PaletteColor
    rgba: PaletteColor
    iconColors: PaletteColor
    icons: PaletteColor
    selection: PaletteColor
    floor: PaletteColor
    csv: PaletteColor
    seat: PaletteColor
    profileIcon: IconBackgroundColors
    facilityList: IconBackgroundColors
    publicPages: IconBackgroundColors
    pricingAlert: IconBackgroundColors
  }
  interface ExtendedTypographyOptions extends TypographyOptions {
    h1: {
      fontWeight: number
      fontSize: string
      lineHeight: number
    }
    h2: {
      fontWeight: number
      fontSize: string
      lineHeight: number
    }
    h3: {
      fontWeight: number
      fontSize: string
      lineHeight: number
    }
    h4: {
      fontWeight: number
      fontSize: string
      lineHeight: number
    }
    h5: {
      fontWeight: number
      fontSize: string
      lineHeight: number
    }
    h6: {
      fontWeight: number
      fontSize: string
      lineHeight: number
    }
    caption: {
      fontWeight: number
      fontSize: string
      lineHeight: number
    }
    body1: {
      fontWeight: number
      fontSize: string
      lineHeight: number
    }
    body2: {
      fontWeight: number
      fontSize: string
      lineHeight: number
    }
    subtitle1: {
      fontWeight: number
      fontSize: string
      lineHeight: number
    }
    subtitle2: {
      fontWeight: number
      fontSize: string
      lineHeight: number
    }
    overline: {
      lineHeight: 1.66
    }
    button: {
      textTransform: 'capitalize'
    }
    drawerTitle: {
      fontWeight: number
      fontSize: string
      lineHeight: number
      color: string
      component: string
    }
    drawerSubtitle1: {
      fontWeight: number
      fontSize: string
      lineHeight: number
      color: string
    }
    drawerSubtitle2: {
      fontWeight: number
      fontSize: string
      lineHeight: number
      color: string
    }
    drawerText: {
      fontWeight: number
      fontSize: string
      lineHeight: number
      color: string
    }
  }

  interface TypographyVariants {
    drawerTitle: React.CSSProperties
    drawerSubtitle1: React.CSSProperties
    drawerSubtitle2: React.CSSProperties
    drawerSubtitle3: React.CSSProperties
    drawerText: React.CSSProperties
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    drawerTitle: React.CSSProperties
    drawerSubtitle1: React.CSSProperties
    drawerSubtitle2: React.CSSProperties
    drawerSubtitle3: React.CSSProperties
    drawerText: React.CSSProperties
  }

  interface ThemeOptions {
    direction?: string
  }
}
export const ThemeCustomization: FC<themeCustomizationProps> = ({ children }): JSX.Element => {
  const theme = palette('light')
  const themeTypography = Typography(['Lexend', 'sans-serif'].join(','), theme)
  const themeCustomShadows = customShadows()
  const themeOptions = useMemo(
    () => ({
      palette: theme.palette,
      direction: 'ltr',
      breakpoints: {
        values: {
          xs: 0,
          sm: 768,
          md: 1024,
          lg: 1266,
          xl: 1536,
        },
      },
      typography: themeTypography as ExtendedTypographyOptions,
      customShadows: themeCustomShadows,
    }),
    [theme, themeTypography, themeCustomShadows],
  )
  const themes = createTheme(themeOptions)
  themes.components = componentsOverride(themes)

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={themes}>
        <CssBaseline />
        {children}
      </ThemeProvider>
    </StyledEngineProvider>
  )
}
