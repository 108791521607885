import { Box, Button, Grid, Stack, Typography } from '@mui/material'
import { ReactNode } from 'react'
import CustomModal from '../@extended/modal'

interface IActionAlertModal {
  showAlert: boolean
  title: string
  alertIcon: JSX.Element
  closeIcon?: JSX.Element
  confirmButtonText?: string
  cancelButtonText?: string
  onAlertConfirm?: () => void
  onAlertClose?: () => void
  isDefaultButtonsVisible?: boolean
  children?: ReactNode
  modalContainerMaxWidth?: string
  focusConfirmButton?: boolean
}

const ModalWithConfirmButtonRight = (props: IActionAlertModal) => {
  const {
    modalContainerMaxWidth,
    onAlertConfirm,
    onAlertClose,
    showAlert,
    closeIcon,
    title,
    alertIcon,
    isDefaultButtonsVisible,
    focusConfirmButton,
    confirmButtonText,
    children,
  } = props

  const confirmAlertModal = (): void => {
    onAlertConfirm?.()
  }

  const closeAlertModal = (): void => {
    onAlertClose?.()
  }

  return (
    <CustomModal isOpen={showAlert}>
      <Grid
        container
        sx={{
          maxWidth: {
            sm: modalContainerMaxWidth ? modalContainerMaxWidth : '27.375rem',
            md: modalContainerMaxWidth ? modalContainerMaxWidth : '27.375rem',
            lg: modalContainerMaxWidth ? modalContainerMaxWidth : '27.375rem',
          },
          minWidth: { sm: '27.375rem', md: '27.375rem', lg: '27.375rem' },
        }}
      >
        {closeIcon && (
          <Grid item xs={12}>
            <Stack justifyContent='end' alignItems='end'>
              <Box
                sx={{
                  height: '.875rem',
                  width: '.875rem',
                  mb: '1.15rem',
                  cursor: 'pointer',
                }}
                onClick={closeAlertModal}
              >
                {closeIcon}
              </Box>
            </Stack>
          </Grid>
        )}

        <Grid item xs={12}>
          <Stack justifyContent='center' alignItems='center'>
            <Box
              sx={{
                height: '2.875rem',
                width: '2.875rem',
                mb: '2.15rem',
              }}
            >
              {alertIcon}
            </Box>

            <Typography
              sx={{
                fontSize: '1.375rem',
                mb: '0.625rem',
                lineHeight: { xs: '1.3', sm: '0.73' },
              }}
              variant='h4'
              textAlign='center'
              color='common.black'
            >
              {title}
            </Typography>

            {children}

            {isDefaultButtonsVisible && (
              <Box p={'0.625rem'} width={'100%'}>
                <Grid container spacing={'1.25rem'}>
                  <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                    <Button
                      sx={{ width: '100%' }}
                      variant={focusConfirmButton ? 'outlined' : 'contained'}
                      size='large'
                      onClick={closeAlertModal}
                    >
                      {props?.cancelButtonText}
                    </Button>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                    <Button
                      sx={{ width: '100%' }}
                      variant={focusConfirmButton ? 'contained' : 'outlined'}
                      size='large'
                      type='submit'
                      onClick={confirmAlertModal}
                    >
                      {confirmButtonText}
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            )}
          </Stack>
        </Grid>
      </Grid>
    </CustomModal>
  )
}

export default ModalWithConfirmButtonRight
